import { Component, OnInit } from '@angular/core';

import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-censi-contato-session',
  templateUrl: './censi-contato-session.component.html',
  styleUrls: ['./censi-contato-session.component.scss']
})
export class CensiContatoSessionComponent implements OnInit { 
  
  

  constructor(public appService:AppService) { }

  ngOnInit() {
   
  }

  ngAfterViewInit(){
    
  }

}
