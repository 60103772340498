<div class="section">
    <div class="form-bg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.56 96.99">
            <defs>
                <style>
                    .cls-1 {
                        fill: #E3E3E2
                    }
                </style>
            </defs>
            <g id="Camada_2" data-name="Camada 2">
                <g id="Camada_1-2" data-name="Camada 1">
                    <path class="cls-1"
                        d="M0,97H335.56V26.82c-14.12-8.28-38-19.86-68.44-22C194.44-.44,159.24,53.18,95.05,49.1,72.78,47.68,39.42,38.84,0,0Z" />
                </g>
            </g>
        </svg>
    </div>
    <table>
        <colgroup>
            <col class="coluna1" />
            <col class="coluna2" />
            <col class="coluna3" />
        </colgroup>
        <tr>
            <th class="th-1">
                <div class="contato-left">
                    <img id="guyImg" src="../../../assets/images/dolls/Agente_03.png" alt="">
                </div>
            </th>
            <th class="th-2">
                <div class="contato-header">
                    <div class="contato-header-title">
                        <span class="title-default title-default__blue">Fale com a censi</span>
                        <hr class="blue-censi-underlined">
                    </div>
                    <div>
                        <span class="subtitle-default">Preencha o formulário ou utilize um dos contatos abaixo</span>
                    </div>
                </div>

                <app-censi-contato-form [mensagem]="'Mensagem'"></app-censi-contato-form>
            </th>
            <th class="th-3">
                <div class="container-right">
                    <div class="area-sac-faq">
                        <div class="area-sac">
                            <div class="icon-sac">
                                <img class="sac" src="/assets/images/icons/sac.svg" alt="">
                            </div>
                            <div>
                                <p class="mini-title-blue">SAC</p>
                                <a class="font-o-s" href="mailto:sacc@censi.com.br?subject=SAC"
                                    title="Sac Censi ">sacc@censi.com.br</a><br />
                                <a class="mini-title-blue font-o-s" href="tel:047-3331-0500">47 3331-0500</a>
                            </div>
                        </div>
                        <div class="area-faq">
                            <div class="icon-faq">
                                <img class="faq" src="/assets/images/icons/faq.svg" alt="icone faq">
                            </div>
                            <p class="mini-title-blue">FAQ</p>
                            <div>
                                <a class="font-o-s" href="">Acesse as perguntas<br />mais frequentes</a>
                            </div>
                        </div>

                    </div>
                    <a target="_blank" href="https://goo.gl/maps/tSNzW2Kc75zzzQhS6">
                        <div class="area-endereco-sac">
                            <div class="icon-img"><img src="/assets/images/icons/localiza.svg" alt=""></div>
                            <div class="font-o-s">Rua Bahia, 4385 - Salto Weissbach<br />
                                Blumenu-SC | CEP 89032-000</div>
                        </div>
                    </a>
                </div>
            </th>
        </tr>
    </table>