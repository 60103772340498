<div class="container-blog">
    <div class="section-header-blog">
        <div class="title-blog pb-2">
            <span class=" hidden sm:block title-default title-default__blue">CENSI <br />
                BLOG</span>
                <span class=" block sm:hidden title-default title-default__blue">CENSI BLOG</span>
            <hr class="blue-censi-underlined" />
            <span class="subtitle-default subtitle-default__blue mt-1 d-block">
                Dicas, matérias, curiosidades e conhecimento geral, em um só lugar.
            </span>
        </div>
        <div class="btn-blog btn-desktop mb-2">
            <button type="submit" routerLink="/blog">Veja outras matérias</button>
        </div>
    </div>
    <div class="section-body-blog">
        <div fxLayout="row nowrap" fxLayoutGap="16px grid">
            <div (click)="openPost(post)" class="card" *ngFor="let post of getPostsInHome()">
                    <div class="card-title"><span [innerHtml]="post.title.rendered"></span></div>        
                    <img class="card-cover" [src]="getCover(post)" alt="imagem blog" />
                    <div style="display: flex;
                    justify-content: end;">
                        <div class="position-date">
                            <p>
                               {{ post.date | date: 'd MMM'}}
                            </p>
                        </div>
                    </div>
                    <div class="card-content">
                        <p [innerHtml]="post.excerpt.rendered"></p>
                    </div>
                    <div class="area-btn-partial">
                        <button class="btn-partial" (click)="openPost(post)">Continuar Lendo >>></button>
                    </div>
            </div>
        </div>
    </div>
    <div class="btn-blog btn-blog-mobile mb-2">
        <button type="submit" routerLink="/blog">Veja outras matérias</button>
    </div>
</div>