<div class="section-slide h-100 w-100 mt-3"> 
    <div fxLayout="row" fxLayoutAlign="space-evenly" class="container-slide h-100">
        <div class="slide-item" *ngFor="let produto of getListPaginated() | sortBy:'ordem'">
            <app-censi-card-produto 
                [produto]="produto" 
                [showBadges]="showBadges">
            </app-censi-card-produto>
        </div>
    </div>
    <button class="button-slide button-slide__left" (click)="goPrev()">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button class="button-slide button-slide__right" (click)="goNext()">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button> 
</div>