import { Component, Input, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Foto } from 'src/app/app.models';

// import Swiper core and required modules
import SwiperCore, { Zoom, Navigation, Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Zoom, Navigation, Pagination]);


@Component({
  selector: 'app-lgpd-modal',
  templateUrl: './lgpd-modal.component.html',
  styleUrls: ['./lgpd-modal.component.scss']
})
export class LgpdModalComponent implements OnInit {

  public closed:boolean = true;
  

  constructor() { }

  
  ngOnInit(): void {
    this.closed = false;
  }
  ngAfterViewInit(): void {
    if(this.getData()){
      this.closeModal();
    } else{
    document?.querySelector("body")?.classList.add("noscroll");
    }
  }

  setData(data: boolean) {
    const jsonData = JSON.stringify(data)
    localStorage.setItem('aceiteLgpd', jsonData)
 }
 clickAceito(){
   this.setData(true);
   this.closeModal();
 }
 
 getData() {
    return localStorage.getItem('aceiteLgpd')
 }

  public showModal(){
    this.closed = false;
    document?.querySelector("body")?.classList.add("noscroll");
  
    
  }
  public closeModal(){
    this.closed = true;
    document?.querySelector("body")?.classList.remove("noscroll");
    
  }
  public downloadPdf(pdfUrl: string, pdfName: string) {
    FileSaver.saveAs(pdfUrl, pdfName);
  }

}


