
<div class="linhasmain">
    <div class="col order-las min-card"
        *ngFor="let linha of categorias | sortBy:'sort'">
        <a class="area-card" [routerLink]="linha.routerLink">
            <div class="linhas-card">
                <div class="area-icon">
                    <img class="linha-img" [src]="linha.icone" [alt]="linha.title">
                </div>
            </div>
            <div class="linha-main-title">{{linha.title}}</div>
            <div class="linha-main-area">
                <div class="linha-main-line"></div>
            </div>
            <div title="{{ linha.subtitle }}" class="linha-main-subtitle">{{ linha.description }}.</div>
        </a>
    </div>
</div>