<mat-sidenav-container>
  <mat-sidenav
    [opened]="false"
    mode="over"
    #sidenav
    class="sidenav mat-elevation-z6"
  >
            <!-- <a class="breadcrumb-menu" routerLink="">
            <mat-icon style="color: #FFF;">keyboard_arrow_left</mat-icon>Categorias
            </a>   -->


        <!-- <button
        mat-icon-button
        color="warn"
        class="close"
        (click)="sidenav.close()"> -->
 
      <!-- <mat-icon color="warn">close</mat-icon>
    </button> -->

    <!-- <div class="title-categoria">
        <p>PRODUTOS</p>
    </div> -->
    <div class="divider"></div>

    <!-- <a class="logo-page" routerLink="">
      <app-logo></app-logo>
    </a> -->
    

    <div [perfectScrollbar]="" class="vertical-menu-wrapper">
      <app-vertical-menu [menuParentId]="''"></app-vertical-menu>
    </div>

    <app-social-icons
      [iconSize]="'lg'"
      [iconColor]="'primary'"
      fxLayout="row"
      fxLayoutAlign="center start"
    ></app-social-icons>
    <div class="divider"></div>
  </mat-sidenav>

  <mat-sidenav-content
    class="page-wrapper"
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <header
      [ngClass]="'toolbar-' + settings.toolbar"
      [class.has-bg-image]="settings.headerBgImage"
      [class.has-bg-video]="settings.headerBgVideo"
      [class.main-toolbar-fixed]="settings.mainToolbarFixed"
    >
      <app-toolbar1
        *ngIf="settings.toolbar == 1"
        (onMenuIconClick)="sidenav.toggle()"
      ></app-toolbar1>
    </header>

    <main class="size-adjuste" [class.content-offset-to-top]="settings.contentOffsetToTop">
      <router-outlet></router-outlet>
    </main>

    <!-- <div class="sticky-footer-helper" fxFlex="grow"></div> -->

   
    <app-footer></app-footer>

    <!-- <div fxLayout="row" fxLayoutAlign="center center" class="options-icon" (click)="options.toggle()">
            <mat-icon>settings</mat-icon>
        </div> -->

    <div
      *ngIf="showBackToTop"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="back-to-top"
      (click)="scrollToTop()"
    >
      <mat-icon>arrow_upward</mat-icon>
    </div>
  </mat-sidenav-content>

  <mat-sidenav #options position="end" class="options" autoFocus="false">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="op-header mat-elevation-z1"
    >
      <h2>Settings</h2>
      <button mat-icon-button (click)="options.toggle()" color="accent">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div [perfectScrollbar]="">
      <div fxLayout="column" class="control">
        <h4>Layout</h4>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>Sticky Menu Toolbar</span>
          <mat-slide-toggle
            [checked]="settings.stickyMenuToolbar"
            (change)="settings.stickyMenuToolbar = !settings.stickyMenuToolbar"
            labelPosition="before"
          ></mat-slide-toggle>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>RTL</span>
          <mat-slide-toggle
            [checked]="settings.rtl"
            (change)="settings.rtl = !settings.rtl"
            labelPosition="before"
          ></mat-slide-toggle>
        </div>
      </div>

      <div fxLayout="column" class="control">
        <h4>Choose header type</h4>
        <mat-radio-group
          [(ngModel)]="headerTypeOption"
          (change)="chooseHeaderType()"
        >
          <mat-radio-button
            *ngFor="let headerType of headerTypes"
            [value]="headerType"
            >{{ headerType }}</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div fxLayout="column" class="control">
        <h4>Choose theme skin</h4>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="skin-primary red" (click)="changeTheme('red')"></div>
          <div class="skin-primary censi" (click)="changeTheme('censi')"></div>
          <div class="skin-primary blue" (click)="changeTheme('blue')"></div>
          <div class="skin-primary green" (click)="changeTheme('green')"></div>
          <div class="skin-primary pink" (click)="changeTheme('pink')"></div>
          <div
            class="skin-primary purple"
            (click)="changeTheme('purple')"
          ></div>
          <div class="skin-primary grey" (click)="changeTheme('grey')"></div>
          <div
            class="skin-primary orange-dark"
            (click)="changeTheme('orange-dark')"
          >
            <div class="skin-secondary"></div>
          </div>
        </div>
      </div>

      <!-- <div fxLayout="column" class="control">
                <h4>Choose search panel variant</h4>
                <mat-radio-group [(ngModel)]="searchPanelVariantOption" (change)="chooseSearchPanelVariant()">
                    <mat-radio-button *ngFor="let searchPanelVariant of searchPanelVariants" [value]="searchPanelVariant">variant-{{searchPanelVariant}}</mat-radio-button>
                </mat-radio-group>
                <mat-slide-toggle [checked]="settings.searchOnBtnClick" (change)="settings.searchOnBtnClick = !settings.searchOnBtnClick" labelPosition="after">Search On Button Click</mat-slide-toggle>
            </div>  -->
    </div>
  </mat-sidenav>
</mat-sidenav-container>
