<!-- instagram -->
<a target="_blank" href="https://bit.ly/3vP4LF5" class="social-icon {{iconClass}}">
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 717.7 717.7"
    style="enable-background: new 0 0 717.7 717.7"
    xml:space="preserve"
  >
    <style type="text/css">
      .st0 {
        fill: #4a1bd8;
      }
    </style>
    <g id="Instagram">
      <path
        class="st0"
        d="M358.4,294.7c-35.9,0.3-64.9,29.6-64.6,65.5c0.3,36,29.6,64.9,65.5,64.7c36-0.3,64.9-29.6,64.6-65.6
		  C423.7,323.4,394.3,294.5,358.4,294.7"
      />
      <path
        class="st0"
        d="M441.4,201.4L274,202.6c-41,0.3-73.9,33.7-73.6,74.7l1.2,167.4c0.3,41,33.7,73.9,74.7,73.6l167.4-1.2
		  c41-0.3,73.9-33.7,73.6-74.7L516.1,275C515.8,234,482.4,201.1,441.4,201.4 M359.5,460.2c-55.5,0.4-100.7-44.3-101.1-99.7
		  c-0.4-55.5,44.3-100.7,99.7-101.1c55.5-0.4,100.7,44.2,101.1,99.7C459.6,414.6,415,459.8,359.5,460.2 M462,278.2
		  c-12.9,0.1-23.5-10.3-23.6-23.2c-0.1-12.9,10.3-23.5,23.2-23.6c12.9-0.1,23.5,10.3,23.6,23.2C485.4,267.6,475,278.1,462,278.2"
      />
      <path
        class="st0"
        d="M356.5,32.2C176.4,33.5,30.9,181,32.2,361.1C33.4,541.2,181,686.7,361.1,685.5s325.6-148.8,324.4-328.9
		  C684.2,176.5,536.7,30.9,356.5,32.2 M552.9,439.3c0.4,61.7-49.2,112-110.9,112.5l-163.6,1.1c-61.7,0.4-112-49.2-112.5-110.9
		  l-1.1-163.6c-0.4-61.7,49.2-112,110.9-112.5l163.6-1.1c61.7-0.4,112.1,49.2,112.5,110.9L552.9,439.3z"
      />
    </g>
  </svg>
</a>

<!--facebook -->
<a target="_blank" href="https://bit.ly/492jTNZ" class="social-icon  {{iconClass}}">
  <!-- <img src="../../../assets/images/social-icons/facebook.svg" alt=""> -->
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 717.7 717.7"
    style="enable-background: new 0 0 717.7 717.7"
    xml:space="preserve"
  >
    <style type="text/css">
      .st0 {
        fill: #ffffff;
      }
    </style>
    <g id="Facebook">
      <path
        class="st0"
        d="M358.8,31.9c-180.1,0-326.6,146.7-326.6,326.9c0,180.3,146.5,326.9,326.6,326.9S685.5,539,685.5,358.8
		S538.9,31.9,358.8,31.9 M440.1,370.3H387v189.6h-78.8V370.3h-37.4v-67h37.4V260c0-31,14.7-79.5,79.5-79.5l58.3,0.3v65h-42.3
		c-6.9,0-16.7,3.5-16.7,18.3v39.4h60L440.1,370.3z"
      />
    </g>
  </svg>

</a>

<!-- pinterest -->
<a target="_blank" href="https://bit.ly/3vIbVLz" class="social-icon {{iconClass}}">
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 717.7 717.7"
    style="enable-background: new 0 0 717.7 717.7"
    xml:space="preserve"
  >
    <style type="text/css">
      .st0 {
        fill: #ffffff;
      }
    </style>
    <g id="Pinterest">
      <path
        class="st0"
        d="M359.1,26.7c-185.2,0-335.4,148.6-335.4,331.8c0,140.7,88.3,260.8,213.1,309.2c-3-26.2-5.5-66.6,1.1-95.3
		c6.1-25.9,39.2-165,39.2-165s-9.9-19.9-9.9-49.2c0-46.2,27.1-80.6,60.7-80.6c28.7,0,42.5,21.3,42.5,46.7c0,28.4-18.2,71-27.9,110.6
		c-8,33.1,16.8,60.1,49.7,60.1c59.6,0,105.5-62.3,105.5-151.8c0-79.5-57.7-134.9-140.2-134.9c-95.5,0-151.6,70.7-151.6,143.9
		c0,28.4,11,59,24.8,75.7c2.8,3.3,3,6.3,2.2,9.6c-2.5,10.4-8.3,33-9.4,37.7c-1.4,6-5,7.4-11.3,4.4c-42-19.4-68.2-79.7-68.2-128.6
		c0-104.6,76.7-200.7,221.7-200.7c116.2,0,206.8,81.9,206.8,191.7c0,114.4-72.9,206.5-173.9,206.5c-34,0-66-17.5-76.7-38.2
		c0,0-16.8,63.4-21,78.9c-7.5,29-27.9,65-41.7,87.1c31.5,9.6,64.6,14.7,99.4,14.7C543.8,691,694,542.4,694,359.2
		C694.5,175.3,544.3,26.7,359.1,26.7"
      />
    </g>
  </svg>
</a>

<!-- linkedin -->
<a target="_blank" href="https://bit.ly/423VKnH" class="social-icon {{iconClass}}">
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 717.7 717.7"
    style="enable-background: new 0 0 717.7 717.7"
    xml:space="preserve"
  >
    <style type="text/css">
      .st0 {
        fill: #ffffff;
      }
    </style>
    <g id="LinkedIn">
      <path
        class="st0"
        d="M358.9,27.7C176,27.7,27.7,176,27.7,358.9S176,690,358.9,690S690,541.8,690,358.9
		C690.1,175.9,541.8,27.7,358.9,27.7 M244.4,554.6c-23.6-0.3-47.1-0.2-70.7,0c-3.8,0-4.9-0.9-4.9-4.8c0.1-84,0.1-167.9,0-251.9
		c0-3.5,0.8-4.7,4.5-4.7c23.9,0.2,47.8,0.2,71.7,0c4.6,0,5.2,1.7,5.2,5.7c-0.1,41.8-0.1,83.6-0.1,125.4c0,41.5-0.1,82.9,0.1,124.4
		C250.3,553.3,249.2,554.6,244.4,554.6 M209.6,257.3c-25.6,0-47-21.2-47-46.9c-0.1-25.9,21.2-47.3,47.1-47.2c25.7,0,47,21.2,47,46.8
		C256.8,236.1,235.6,257.3,209.6,257.3 M555.1,549.2c0,4.3-1.2,5.4-5.4,5.4c-23.6-0.2-47.1-0.2-70.7,0c-4.2,0-5-1.2-5-5.2
		c0.1-43.5,0.2-87.1,0-130.6c0-10.9-0.7-21.8-3.8-32.4c-5.6-19.5-19.6-29.5-40.1-28.4c-28,1.5-42.5,15.3-46.1,43.7
		c-0.9,6.8-1.3,13.6-1.2,20.4c0,42.3,0,84.7,0.1,127c0,4.2-1,5.5-5.4,5.4c-23.7-0.2-47.5-0.2-71.2,0c-3.8,0-4.9-1-4.9-4.9
		c0.1-83.8,0.1-167.7,0-251.5c0-4.1,1.4-5.1,5.3-5.1c22.5,0.2,45.1,0.2,67.6,0c4.1,0,5.2,1.3,5.1,5.2c-0.2,10-0.1,20-0.1,30.1
		c5.4-6.1,9.9-12.3,15.5-17.6c17.2-16.2,37.5-24.4,61.2-24.2c13,0.1,25.9,1.1,38.5,4.7c28.7,8.3,45.5,28.1,53.4,56.2
		c6,21.1,7.1,42.7,7.1,64.4C555.2,457.8,555,503.5,555.1,549.2"
      />
    </g>
  </svg>
</a>
<!-- youtube -->
<a target="_blank" href="https://bit.ly/422MXma" class="social-icon {{iconClass}}">
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 717.7 717.7"
    style="enable-background: new 0 0 717.7 717.7"
    xml:space="preserve"
  >
    <style type="text/css">
      .st0 {
        fill: #ffffff;
      }
    </style>
    <g id="YouTube">
      <path
        class="st0"
        d="M358.8,31.9c-180.1,0-326.6,146.7-326.6,326.9c0,180.3,146.5,327,326.6,327s326.7-146.7,326.7-327
		C685.5,178.6,539,31.9,358.8,31.9 M422.9,222.1h31.2v101.2c0,3.1,0.7,5.4,2,6.8s3.5,2.1,6.4,2.1c2.3,0,5.2-0.9,8.7-2.7
		c3.5-1.9,6.7-4.2,9.7-7.1V222.1h31.2v132h-31.2v-14.5c-5.8,5.3-11.7,9.4-18,12.3c-6.2,2.8-12.2,4.2-18.1,4.2
		c-7.2,0-12.7-1.9-16.3-5.8c-3.7-4-5.5-9.8-5.5-17.7L422.9,222.1L422.9,222.1z M307.6,253.5c0-10.3,4.2-18.4,12.6-24.4
		c8.4-6,19.7-9.1,33.8-9.1c12.9,0,23.4,3.2,31.7,9.6c8.3,6.4,12.3,14.7,12.3,24.7v68.2c0,11.3-4,20.2-12.1,26.6s-19.2,9.7-33.4,9.7
		c-13.6,0-24.5-3.3-32.8-9.9c-8.2-6.6-12.2-15.5-12.2-26.8L307.6,253.5L307.6,253.5z M222.2,178.8l22.5,69.6h2.2l21.6-69.6h35.3
		l-40.5,101.9V353h-34.9v-69L187,178.9L222.2,178.8L222.2,178.8z M535.3,525.8c0,22.9-23.5,41.4-52.4,41.4
		c-41.3,1.4-83.5,2-126.6,1.9c-43.1,0-85.3-0.5-126.6-1.9c-28.9,0-52.4-18.5-52.4-41.4c-1.8-18.1-2.5-36.2-2.5-54.3
		c-0.1-18.1,0.7-36.2,2.5-54.3c0-22.9,23.4-41.5,52.4-41.5c41.3-1.4,83.5-2,126.6-1.9c43.1-0.1,85.3,0.5,126.6,1.9
		c29,0,52.4,18.6,52.4,41.5c1.7,18,2.5,36.1,2.4,54.3C537.8,489.6,537,507.7,535.3,525.8"
      />
      <path
        class="st0"
        d="M352.5,336c4.2,0,7.6-1,10.1-3.1c2.5-2,3.7-4.8,3.7-8.3v-71.9c0-2.9-1.2-5.2-3.8-7c-2.5-1.7-5.9-2.7-10-2.7
		c-3.8,0-6.9,0.9-9.2,2.7c-2.4,1.8-3.6,4.1-3.6,7v71.9c0,3.6,1.1,6.4,3.3,8.4C345.3,335,348.4,336,352.5,336"
      />
      <path
        class="st0"
        d="M404.9,437.9c-3.4,0-6.8,0.7-10.1,2.3c-3.4,1.5-6.5,3.6-9.5,6.6v-41.3h-22.2v128.3h22.2v-7.3
		c2.8,2.9,6,5,9.4,6.4s7.2,2,11.6,2c6.5,0,11.4-1.8,14.9-5.3c3.5-3.6,5.2-8.7,5.2-15.5v-52.5c0-7.7-1.8-13.6-5.5-17.7
		C417.2,440,411.9,437.9,404.9,437.9 M403.7,511.6c0,3-0.7,5.2-1.9,6.5c-1.3,1.3-3.3,2-5.9,2c-1.9,0-3.6-0.3-5.3-1
		c-1.7-0.6-3.4-1.7-5.2-3.3v-59c1.5-1.3,3-2.2,4.5-2.9c1.5-0.6,3.1-0.9,4.6-0.9c3,0,5.3,0.8,6.8,2.5c1.6,1.7,2.4,4.1,2.4,7.3
		L403.7,511.6L403.7,511.6z"
      />
      <path
        class="st0"
        d="M476.4,436.7c-9.8,0-17.9,2.6-24.1,7.8c-6.3,5.2-9.4,11.9-9.4,20.1v42.5c0,9.1,2.8,16.3,8.5,21.5
		c5.7,5.2,13.4,7.8,23.3,7.8c10.9,0,19.2-2.5,24.7-7.3c5.5-4.9,8.3-12.3,8.3-21.9V500H485v6.6c0,5.6-0.7,9.2-2.2,10.8
		c-1.4,1.6-4,2.5-7.6,2.5c-3.5,0-6-1-7.5-3c-1.4-1.9-2.1-5.3-2.1-10.3v-17.9h42.1v-24.1c0-9-2.7-15.9-8.2-20.7
		C494.2,439.1,486.5,436.7,476.4,436.7 M485.1,474h-19.4v-9.6c0-4,0.8-6.8,2.3-8.5s4-2.6,7.6-2.6c3.4,0,5.7,0.9,7.3,2.6
		c1.5,1.8,2.3,4.5,2.3,8.5v9.6H485.1z"
      />
      <polygon
        class="st0"
        points="279.1,405.5 203.1,405.5 203.1,424.1 228.7,424.1 228.7,533.8 253.5,533.8 253.5,424.1 279.1,424.1 	
		"
      />
      <path
        class="st0"
        d="M319.6,511c-2.1,2.1-4.3,3.7-6.8,5.1c-2.5,1.3-4.5,2-6.1,2c-2.1,0-3.6-0.5-4.5-1.5s-1.4-2.6-1.4-4.9v-72.6h-22
		v79.1c0,5.7,1.3,9.9,3.9,12.7c2.6,2.8,6.4,4.2,11.4,4.2c4.2,0,8.4-1,12.9-3c4.3-2,8.6-4.9,12.7-8.8v10.4h22V439h-22L319.6,511
		L319.6,511z"
      />
    </g>
  </svg>
</a>
