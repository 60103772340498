<footer>
    <div class="footer-1">
        <div class="py-2 content border-lighter">
            <div fxLayout="row wrap" fxLayoutAlign="space-around" style="align-items: center;">
                <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.gt-xs="40" class="footer-col-1">
                    <div style="margin: 10px;">
                        <a class="logo" routerLink="">
                            <app-logo [size]="2"></app-logo>
                        </a>
                        <p class="mt-4 mb-3 footer-text font-footer-default">Soluções em reparos e mecanismos
                            hidráulicos inovadores,
                            concebidos através da atenta observação das tendências do mercado, com a missão de
                            melhorar produtos e procedimentos já existentes, além da constante renovação das linhas.
                        </p>
                    </div>
                </div>

                <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.gt-xs="30" ngClass.lt-md="pt-5"
                    class="footer-sobre ml-50 fw-600">
                    <div>
                        <h1 class="secondary-font">SOBRE A CENSI</h1>
                        <p class="font-footer-default"><a (click)="scrollToTop()" routerLink="sobre">A CENSI</a></p>
                        <p class="font-footer-default"><a (click)="scrollToTop()" href="https://censi.gupy.io/" target="_blank">Trabalhe Conosco</a></p>
                        <p class="font-footer-default"><a (click)="scrollToTop()" href="https://blog.censi.com.br" target="_blank">Blog</a></p>
                        <p class="font-footer-default"><a (click)="scrollToTop()" routerLink="contato">Contato</a></p>
                        <app-social-icons class="d-flex"></app-social-icons>
                    </div>

                </div>

                <div fxFlex="50" fxFlex.gt-sm="20" fxFlex.gt-xs="30" ngClass.lt-md="pt-5"
                    class="footer-suporte fw-600">
                    <h1 class="secondary-font mb-3">SUPORTE</h1>
                    <p fxLayout="row" fxLayoutAlign="start center"
                        class="size-icon font-footer-default font-footer-default__dark">
                        <img src="assets/images/icons/phone-call.png" alt="telefone">
                        <a class="" href="tel:047-3331-0500">47 3331-0500</a>
                    </p>
                    <p fxLayout="row" fxLayoutAlign="start center"
                        class="size-icon font-footer-default font-footer-default__dark">
                        <img src="assets/images/icons/email.png" alt="e-mail">
                        <a class="" href="mailto:sacc@censi.com.br?subject=SAC"
                            title="Sac Censi">sacc&#64;censi.com.br</a>
                    </p>
                    <!-- <p fxLayout="row" fxLayoutAlign="start center"
                        class="size-icon font-footer-default font-footer-default__dark ml-50-m">
                        <img src="assets/images/icons/fax.png" alt="">
                        <span class="mx-2">+55 47 3331-0501</span>
                    </p> -->

                </div>
                <div fxFlex="50" fxFlex.gt-sm="20" fxFlex.gt-xs="30" ngClass.lt-md="pt-5" class="footer-links fw-600">
                    <h1 class="secondary-font mb-3">LINKS ÚTEIS</h1>
                    <a (click)="scrollToTop()" routerLink="categorias">
                        <div fxLayout="row" fxLayoutAlign="start center"
                            class="size-icon font-footer-default font-footer-default__dark ">
                            <img src="assets/images/icons/water-filter@2x.png" alt="icone de produtos">
                            <p class="color-text-footer">PRODUTOS</p>
                        </div>
                    </a>
                    <p fxLayout="row" fxLayoutAlign="start center" class="size-icon ">
                        <img src="assets/images/icons/noun_Question_5742.svg" alt="FAQ">
                        <a (click)="scrollToTop()" routerLink="/area_do_cliente/perguntas_frequentes"
                            class="font-footer-default font-footer-default__dark">FAQ</a>
                    </p>
                    <div class="social-mobile" style="display: none;">
                        <app-social-icons [iconColor]="'secondary'" [iconSize]="'lg'" fxLayout="row"
                            fxLayoutAlign="start center" class="color-text-footer "></app-social-icons>
                    </div>
                </div>
            </div>
        </div>



        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
            <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.5)" />
                <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                <use xlink:href="#gentle-wave" x="48" y="10" fill="#fff" />
            </g>
        </svg>
    </div>

    <div class="footer-2">
        <div class="footer-2--copy">
            <p class="footer-Copyright font-footer-2" ngClass.xs="mt-1"><a class="lgpd-text" routerLink="/lgpd">Politica de Privacidade e LGPD</a> / <a class="lgpd-text" routerLink="/compliance">Política
                de Compliance</a><br />Copyright ©
                2022 Todos os direitos reservados <a class="show-footer" href="/">CENSI Sistemas Hidrossanitários</a></p>
            <p class="footer-bot font-footer-2"><a target="_blank" href="https://bit.ly/42bIiyf">Rua Bahia, 4385 -
                    Salto Weissbach<br />Blumenau - SC | 89032-000</a></p>
            <p class="footer-info-develop font-footer-2">Designed & Developed by&ensp;<a class="bnt-designed"
                    href="https://www.msinovacao.com.br" target="_blank">MS Inovação</a></p>
        </div>
    </div>

</footer>