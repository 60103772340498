import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, Order, Category } from 'src/app/app.models'; 
import { AppSettings } from 'src/app/app.settings'; 
import { environment } from 'src/environments/environment';   
import { ConfirmDialogComponent, ConfirmDialogModel } from './shared/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { map } from 'rxjs/operators';

export class Data {
  constructor(public categories: Category[], 
              public cartList: MenuItem[],
              public orderList: Order[],
              public favorites: MenuItem[], 
              public totalPrice: number,
              public totalCartCount: number
              ) { }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public Data = new Data(
    [],  // categories 
    [],  // cartList
    [],  // orderList
    [],  // favorites 
    0, // totalPrice
    0 //totalCartCount
  )  
  
  public url = environment.url + '/assets/data/'; 
  public censi_url = environment.url + '/assets/data/censi/'; 
  
  constructor(public http:HttpClient, 
              private datePipe:DatePipe,
              private bottomSheet: MatBottomSheet, 
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
              public appSettings:AppSettings,
              public translateService: TranslateService) { }  

     
 
public getMenuItems(): Observable<MenuItem[]>{
    return this.http.get<MenuItem[]>(this.url + 'menu-items.json');
} 
 
  // public getMenuItemById(id:number): Observable<MenuItem>{
  //   return this.http.get<MenuItem>(this.url + 'menu-item-' + id + '.json');
  // }
 
  // public getSpecialMenuItems(): Observable<MenuItem[]>{
  //   return this.http.get<MenuItem[]>(this.url + 'special-menu-items.json');
  // } 

  // public getBestMenuItems(): Observable<MenuItem[]>{
  //   return this.http.get<MenuItem[]>(this.url + 'best-menu-items.json');
  // } 

  public getCategories(): Observable<Category[]>{
    return this.http.get<Category[]>(this.url + 'categories.json');
  }  

  public getHomeCarouselSlides(){
    return this.http.get<any[]>(this.url + 'slides.json');
  }

  public getReservations(){
    return this.http.get<any[]>(this.url + 'reservations.json');
  }

  public getOrders(){
    return this.http.get<any[]>(this.url + 'orders.json');
  }

  public getFaqs(){
    return this.http.get<any[]>(this.censi_url + 'perguntas.json');
  }

  public getGUID(){
    let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    }).toLowerCase();
    return guid;
  }

  // public addToCart(menuItem:MenuItem, component:any){   
  //   if(!this.Data.cartList.find(item=>item.id == menuItem.id)){
  //     menuItem.cartCount = (menuItem.cartCount) ? menuItem.cartCount : 1;
  //     this.Data.cartList.push(menuItem); 
  //     this.calculateCartTotal(); 
  //     if(component){
  //       this.openCart(component);        
  //     }
  //     else{ 
  //       this.snackBar.open('The menu item "' + menuItem.name + '" has been added to cart.', '×', {
  //         verticalPosition: 'top',
  //         duration: 3000,
  //         direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
  //         panelClass: ['success'] 
  //       });
  //     }
  //   }  
  // } 

  // public openCart(component:any){  
  //   this.bottomSheet.open(component, {
  //     direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr'
  //   }).afterDismissed().subscribe(isRedirect=>{  
  //     if(isRedirect){ 
  //       // window.scrollTo(0,0);  
  //     }        
  //   });  
  // }

  // public calculateCartTotal(){
  //   this.Data.totalPrice = 0;
  //   this.Data.totalCartCount = 0;
  //   this.Data.cartList.forEach(item=>{
  //     let price = 0;
  //     if(item.discount){
  //       price = item.price - (item.price * (item.discount / 100));
  //     }
  //     else{
  //       price = item.price;
  //     }
  //     this.Data.totalPrice = this.Data.totalPrice + (price * item.cartCount); 
  //     this.Data.totalCartCount = this.Data.totalCartCount + item.cartCount;  
  //   });
  // }

  // public addToFavorites(menuItem:MenuItem){
  //   let message:string, status:string;
  //   if(this.Data.favorites.find(item=>item.id == menuItem.id)){ 
  //     message = 'The menu item "' + menuItem.name + '" already added to favorites.'; 
  //     status = 'error';    
  //   } 
  //   else{
  //     this.Data.favorites.push(menuItem);
  //     message = 'The menu item "' + menuItem.name + '" has been added to favorites.'; 
  //     status = 'success';  
  //   } 
  //   this.snackBar.open(message, '×', {
  //     verticalPosition: 'top',
  //     duration: 3000,
  //     direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr',
  //     panelClass: [status] 
  //   });   
  // }

  public openDialog(component:any, data:any, panelClass:any){ 
    return this.dialog.open(component, {
      data: data, 
      panelClass: panelClass,
      autoFocus: false,
      direction: (this.appSettings.settings.rtl) ? 'rtl':'ltr'
    });  
  }

  public openConfirmDialog(title:string, message:string) {  
    const dialogData = new ConfirmDialogModel(title, message); 
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    }); 
    return dialogRef; 
  }

  public openAlertDialog(message:string) {   
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: "400px",
      data: { "message":message,"type":1}
    }); 
    return dialogRef; 
  }

  public openErrorDialog(message:string) {   
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: "400px",
      data:{ "message":message,"type":2}

    }); 
    return dialogRef; 
  }


  public makeReservation(dialogComponent:any, data:any, onDialog:boolean = false){
    if(onDialog){
      const dialogRef = this.openDialog(dialogComponent, null, 'theme-dialog');
      dialogRef.afterClosed().subscribe(data => {  
        if(data){ 
          this.showReservationMessage(data);
        } 
      }); 
    }
    else{
      this.showReservationMessage(data);
    } 
  }
  private showReservationMessage(data:any){
    this.snackBar.open('Dear ' + data.fullName + ', thank you for your reservation! Your reservation at Popino on the '+ this.datePipe.transform( data.date,'dd-MM-yyyy') +' at '+ data.time +' for '+ data.guests +' people will review by our team and someone will be in touch soon.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 9000 });
  } 

  public getTranslateValue(key:string, param:string = ''){  
    let value = null;
    this.translateService.get(key, { param: param }).subscribe((res: string) => {
      value = res;
    }) 
    return value; 
  } 

  public filterData(data:any, categoryId:number, sort?:string, page?:number, perPage?:number){  
    if(categoryId){
      data = data.filter((item:any) => item.categoryId == categoryId);
    }   

    //for show more properties mock data 
    // for (var index = 0; index < 2; index++) {
    //   data = data.concat(data);        
    // }     
     
    this.sortData(sort, data);
    return this.paginator(data, page, perPage)
  }

  public sortData(sort?:string, data?:any){
    if(sort){
      switch (sort) { 
        case 'Popular':
          data = data.sort((a:any, b:any) => { 
            if(a.ratingsValue/a.ratingsCount < b.ratingsValue/b.ratingsCount){
              return 1;
            }
            if(a.ratingsValue/a.ratingsCount > b.ratingsValue/b.ratingsCount){
              return -1;
            }
            return 0; 
          });
          break;
        case 'Price (Low to High)': 
          data = data.sort((a:any,b:any) => {
            if(a.price > b.price){
              return 1;
            }
            if(a.price < b.price){
              return -1;
            }
            return 0;  
          });
          break;
        case 'Price (High to Low)': 
          data = data.sort((a:any,b:any) => {
            if(a.price < b.price){
              return 1;
            }
            if(a.price > b.price){
              return -1;
            }
            return 0;  
          }); 
          break;
        default:
          break;
      }
    }
    return data;
  }

  public paginator(items:any, page?:any, perPage?:any) { 
    var page = page || 1,
    perPage = perPage || 4,
    offset = (page - 1) * perPage,   
    paginatedItems = items.slice(offset).slice(0, perPage),
    totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination:{
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
      }
    };
  }  
  
  public getPostsHome(){
    return [
      {          
        id: '547896',  
        name: 'Dia da Natureza',
        description: 'Descrição do Produto',
        cover: 'https://picsum.photos/300/200?random=1',
        url: '...',
        dataPublicacao: '10/04/2021',
        body: 'A data de hoje (04/10) foi criada com o intuito de incentivar a reflexão sobre o cuidado com os animais e a preservação com a natureza.'
      }, {
        id: '547896',  
        name: 'Dica: Caixa D\'água',
        description: 'Descrição do Produto',
        cover: 'https://picsum.photos/300/200?random=2',
        url: '...',
        dataPublicacao: '09/09/2021',
        body: 'Antes de mais nada, você deve saber a quantidade mínima de água que sua família consome diariamente, para decidir qual o tamanho ideal da caixa.'
      }, {          
        id: '547896',  
        name: 'Escolha do Chuveiro',
        description: 'Descrição do Produto',
        cover: 'https://picsum.photos/300/200?random=3',
        url: '...',
        dataPublicacao: '07/30/2021',
        body: 'Além de suportar uma boa temperatura e pressão, o design também é importante para garantir um ambiente moderno e confortável.'
      }, {          
        id: '547896',  
        name: 'Acessibilidade na banheiro',
        description: 'Descrição do Produto',
        cover: 'https://picsum.photos/300/200?random=4',
        url: '...',
        dataPublicacao: '07/15/2021',
        body: 'Para o banheiro, entre dragon pharma anavar as peças destaques no catálogo da Censi, está o mecanismo de saída de água Soft Touch.'
      }
    ]
  }

  public getVideosHome(){
    return [
      {          
        id:'547896' ,  
        name:'Produto Exemplo',
        description:'Descrição do Produto',
        cover: 'assets/images/produtos/produto1.png',
        url: '...',

    },
    {          
      id:'547896' ,  
      name:'Produto Exemplo',
      description:'Descrição do Produto',
      cover: 'assets/images/produtos/produto1.png',
      url: '...',

  },
  {          
    id:'547896' ,  
    name:'Produto Exemplo',
    description:'Descrição do Produto',
    cover: 'assets/images/produtos/produto1.png',
    url: '...',

},
{          
  id:'547896' ,  
  name:'Produto Exemplo',
  description:'Descrição do Produto',
  cover: 'assets/images/produtos/produto1.png',
  url: '...',

},

  ]
  }

  public getLancamentosHome(){
    return [
      {          
        codPro:'547896' ,  
        name:'Produto Exemplo',
        description:'Descrição do Produto',
        image: '../assets/75100-300x300.png' 
    },
    {          
      codPro:'547896' ,  
      name:'Produto Exemplo',
      description:'Descrição do Produto',
      image: '../assets/75100-300x300.png' 
  },
  {          
    codPro:'547896' ,  
    name:'Produto Exemplo',
    description:'Descrição do Produto',
    image: '../assets/75100-300x300.png' 
},
{          
  codPro:'547896' ,  
  name:'Produto Exemplo',
  description:'Descrição do Produto',
  image: '../assets/75100-300x300.png' 
},
{          
  codPro:'547896' ,  
  name:'Produto Exemplo',
  description:'Descrição do Produto',
  image: '../assets/75100-300x300.png' 
},
{          
  codPro:'547896' ,  
  name:'Produto Exemplo',
  description:'Descrição do Produto',
  image: '../assets/75100-300x300.png' 
},
{          
  codPro:'547896' ,  
  name:'Produto Exemplo',
  description:'Descrição do Produto',
  image: '../assets/75100-300x300.png' 
},
{          
  codPro:'547896' ,  
  name:'Produto Exemplo',
  description:'Descrição do Produto',
  image: '../assets/75100-300x300.png' 
},
{          
  codPro:'547896' ,  
  name:'Produto Exemplo',
  description:'Descrição do Produto',
  image: '../assets/75100-300x300.png' 
},
{          
      codPro:'547896' ,  
      name:'Produto Exemplo',
      description:'Descrição do Produto',
      image: 'assets/images/produtos/produto1.png' 
  }
  ]

  }

  public getLinhasHome(){
    return [
      {          
        id:'' ,  
        title:'Acessibilidade',
        description:'Barras de apoio',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Caixas Acopladas',
        description:'Mecanismos de entrada e saída de Água, acionadores, vedantes, etc',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Sistemas de descarga',
        description:'Reparos, acabamentos, teclas, suportes, guarnições, etc',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Acessórios para banheiro',
        description:'Duchas higiênicas e grelhas',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Economizadores',
        description:'Produtos que ajudam na economia de água',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Pias, lavatórios e tanques',
        description:'Sifões tubos extensíveis, válvulas, acessórios, etc',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:"Caixa d'água",
        description:'Torneiras de válvula, Boias e etc',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Filtro de água',
        description:'Adaptadores',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Gás',
        description:'Engates, registros, joelhos, adaptadores, etc',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'MVS',
        description:'Mecanismos de vedação substituível',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Reparos para registros e torneiras',
        description:'Reparos, complementos, módulos, etc',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:"Anéis o'ring e vedantes",
        description:"Anéis o'ring, vedantes, etc",
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Bacias sanitárias',
        description:'Anéis de vedação, assentos, tubos de ligação, etc',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Máquinas de lavar',
        description:'Torneiras e conexões',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Conexões',
        description:'Aumentos, buchas, conexões em "T", extensões, joelhos, etc',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
      {          
        id:'' ,  
        title:'Ferramentas',
        description:'Ferramentas, chaves, lubrificante, lixas, potes organizadores, etc',
        image: 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/icons/icon-categoria-example.png' 
      },
    ];
  }


  // public getAwards(){
  //   return [  
  //       { name: 'award-1', image: 'assets/images/awards/1.png' },
  //       { name: 'award-2', image: 'assets/images/awards/2.png' },
  //       { name: 'award-3', image: 'assets/images/awards/3.png' },
  //       { name: 'award-4', image: 'assets/images/awards/4.png' },
  //       { name: 'award-5', image: 'assets/images/awards/5.png' },
  //       { name: 'award-6', image: 'assets/images/awards/6.png' },
  //       { name: 'award-7', image: 'assets/images/awards/7.png' } 
  //   ];
  // }

  public picturesFactory(){
    return [  
        { name: 'picFac-1', image: 'assets/images/fotos_censi/sobre_fotos_1.png'},
        { name: 'picFac-2', image: 'assets/images/fotos_censi/sobre_fotos_2.png'},
        { name: 'picFac-3', image: 'assets/images/fotos_censi/sobre fotos_3.png'},
        { name: 'picFac-4', image: 'assets/images/fotos_censi/sobre_fotos_4.png'},
        { name: 'picFac-5', image: 'assets/images/fotos_censi/sobre fotos_5.png'},
        { name: 'picFac-6', image: 'assets/images/fotos_censi/sobre_fotos_6.png'},
        { name: 'picFac-7', image: 'assets/images/fotos_censi/sobre fotos_7.png'},
        { name: 'picFac-8', image: 'assets/images/fotos_censi/sobre_fotos_8.png'},
        { name: 'picFac-9', image: 'assets/images/fotos_censi/sobre fotos_9.png'},
        { name: 'picFac-10', image: 'assets/images/fotos_censi/sobre_fotos_10.png'},
        { name: 'picFac-11', image: 'assets/images/fotos_censi/sobre fotos_11.png'},
        { name: 'picFac-12', image: 'assets/images/fotos_censi/sobre_fotos_12.png'}
    ];
  }

  public getDeliveryMethods(){
    return [
        { value: 'free', name: 'Free Delivery', desc: '$0.00 / Delivery in 7 to 14 business Days' },
        { value: 'standard', name: 'Standard Delivery', desc: '$7.99 / Delivery in 5 to 7 business Days' },
        { value: 'express', name: 'Express Delivery', desc: '$29.99 / Delivery in 1 business Days' }
    ]
  }

  public getYears(){
    const startYear = new Date().getFullYear();
    let years = Array();  
    for (let i = 0; i <= 10; i++) { 
      years.push(startYear + i);      
    }   
    return years; 
  } 

  public shuffleArray(array:any){
    var currentIndex = array.length, temporaryValue, randomIndex; 
    while (0 !== currentIndex) { 
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1; 
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    } 
    return array;
  } 

  public convertImgToBase64(url:string, callback:any){
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  
  private mailApi = 'https://mailthis.to/codeninja'
  public PostMessage(input: any) {
    return this.http.post(this.mailApi, input, { responseType: 'text' })
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  } 

}
