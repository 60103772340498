<!-- <div class="section default mobile-app mt-0 pb-0"> -->
    <div class="theme-container">  
        <div fxLayout="row wrap">
            <div fxFlexAlign.gt-sm="center text-center" fxFlex.gt-md="50" class="img-app">
                <img src="assets/images/app_de_consulta.png" alt="mobile-app" class="mw-50">
            </div>
            <div fxFlex.gt-md="50" class="cons-app">
                <h1 class="title-default title-default__blue">APP DE CONSULTA<br/></h1>
                <hr class="blue-censi-underlined">
                <p class="mw-space-app">A Censi possui uma vasta rede de representantes espalhada por todo Brasil, buscando levar ao acesso dos profissionais da área, produtos práticos, de fácil instalação e manutenção.</p>
                <div fxLayout="row wrap" fxLayoutAlign="start center" class="pt-3">
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=morphy.com.br.censi&hl=pt_BR&gl=US&showAllReviews=true">
                        <img src="assets/images/others/app-download-btn1.png" alt="btn1" class="img-google-play mw-100 d-block m-3">
                        <!-- <img src="assets/images/others/app-download-btn2.png" alt="btn2" class="mw-100 d-block m-1"> -->
                    </a>
                </div> 
            </div> 
        </div>  
    </div>
<!-- </div> -->