export class Menu {
    constructor(public id: string,
                public title: string,
                public subtitle: string | null,
                public routerLink: string | null,
                public type: string = 'ROUTE',
                public href: string | null, 
                public target: string | null,                
                public hasSubMenu: boolean,
                public parentId: string,
                public sort: number = 0,                
                public description: string | null,                
                public icone: string | null,                
                public icone2: string | null,
                public icone_alt: string | null,                
                public cover: string | null,                
                public selected: boolean = false,                
                
                ) { }
} 