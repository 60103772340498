
<ng-container  class="color-blue-white">
  <!-- <div *ngIf="menuItem && menuItem.menuParentId != '' ">
    <p class="title-categoria">CATEGORIAS</p>
  </div>  -->
  <a  mat-button fxLayout="row" fxLayoutAlign="start center"
        routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"        
        (click)="onBack()" [id]="'menu-item-back'">
        <div style="margin: 10px 0 10px -8px" *ngIf="menuItem && menuItem.menuParentId != '' " >
          <span class="menu-title"><mat-icon>keyboard_arrow_left</mat-icon> MENU PRINCIPAL</span>
          <hr/>
        </div>  
  </a>

    <!-- <div *ngIf="menuItem && menuItem.menuParentId != '' ">
      <p class="title-categoria title-categoria__produtos">PRODUTOS</p>
    </div> -->
    <div *ngIf="!menuItem">
      <a class="logo-vertical" routerLink="">
        <app-logo></app-logo>
      </a>
    </div>
</ng-container>
  

<ng-container  class="color-blue-white" *ngFor="let menuItem of menuItems | sortBy:'sort'">
    <a *ngIf="menuItem.routerLink && !menuItem.hasSubMenu" mat-button fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="[menuItem.routerLink]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"        
      (click)="onClick(menuItem)" [id]="'menu-item-'+menuItem.id">
      <span class="menu-title">{{ menuItem.title | translate }}</span>
  </a>
  <a *ngIf="!menuItem.routerLink && menuItem.href" mat-button fxLayout="row" fxLayoutAlign="start center"
  [href]="[menuItem.href]" target="_blank" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"        
  (click)="onClick(menuItem)" [id]="'menu-item-'+menuItem.id">
  <span class="menu-title">{{ menuItem.title | translate }}</span>
</a>
</ng-container>

<ng-container  class="color-blue-white" *ngFor="let menuItem of menuItems | sortBy:'sort':'desc'">
  
  <a *ngIf="menuItem.hasSubMenu" mat-button fxLayout="row" fxLayoutAlign="start center"       
      (click)="onClick(menuItem)" [id]="'menu-item-'+menuItem.id">
      <span class="menu-title">{{ menuItem.title | translate }}</span>
      <mat-icon class="menu-expand-icon transition-2">arrow_drop_down</mat-icon>
  </a>

</ng-container>

