<h1 mat-dialog-title>
    <div class="text-center">
        <mat-icon  *ngIf="data.type == 1" class="mat-icon-xlg mat-succes">check_circle</mat-icon>
        <mat-icon  *ngIf="data.type == 2"class="mat-icon-xlg mat-warn">error</mat-icon>
    </div>
</h1>
  
<div mat-dialog-content >
    <p>{{data.message}}</p>
</div>
  
<div mat-dialog-actions> 
    <button mat-raised-button color="primary" (click)="close()" class="m-auto">{{ 'BTN.OK' | translate }}</button>
</div>