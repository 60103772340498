import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { filter, Observable } from 'rxjs';

import { AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment';

import { Contato, DesenhoTecnico, Download, Estado, Foto, FotoSobre, Post, Produto, Relacionado } from './app.models';
import { Menu } from './theme/components/menu/menu.model';
import { Video } from './shared/censi-videos/censi-videos.component';

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    produtos: Array<Produto> = [];
    capas: any[] = [];
    posts: Array<Post> = [];
    post: any;
    categoriaName = '';
    filteredPosts: Array<Post> = [];
    categorias: Array<Menu> = [];
    relacionados: Array<any> = [];
    cidades: Array<any> = [];
    postsEventEmitter: EventEmitter<any> = new EventEmitter<any>();
    postEventEmitter: EventEmitter<any> = new EventEmitter<any>();

    public url = `${environment.url}/assets/data/`;
    public censi_url = `${environment.url}/assets/data/censi`;
    public yScroll = 0;

    constructor(
        public http: HttpClient,
        public appSettings: AppSettings
    ) {
        this.loadProducts();
        this.loadCategorias();
        this.loadProductsRelacionados();
        this.getEstadosCidades();
    }

    loadProducts() {
        this.http.get<Produto[]>(`${this.censi_url}/produtos.json`)
            .subscribe(itens => {
                this.produtos = itens;
            }
            );
    }

    loadProductsRelacionados() {
        this.http.get<any[]>(`${this.censi_url}/relacionados.json`)
            .subscribe(itens => {
                this.relacionados = itens;
            }
            );
    }

    loadCategorias() {
        this.http.get<Menu[]>(`${this.censi_url}/categorias.json`)
            .subscribe(itens => {
                this.categorias = itens;
            }
            );
    }

    public getAllProducts(): Observable<Produto[]> {
        return this.http.get<Produto[]>(`${this.censi_url}/produtos.json`);
    }

    public getAllBanners(): Observable<any[]> {
        return this.http.get<any[]>(`${this.censi_url}/banners.json`);
    }

    public getProdutosPorCategoria(id: string): Array<Produto> {
        let filtrados: Array<Produto> = [];
        this.produtos.forEach(produto => {
            if(produto.categorias != null){
                let categorias = produto.categorias.split(", ")
                categorias.forEach(element => {
                    if(element == id){
                        filtrados.push(produto);
                    } 
                });
            }
        });

        return filtrados;

    }

    public getProdutosRelacionados(id: string): Array<Produto> {
        let relacionados: Array<Produto> = [];

        this.relacionados.forEach(relacao => {
            if (relacao.produto == id) {
                let p = this.buscarProdutoPorCodigo(relacao.codPro);
                if (p) {
                    relacionados.push(p);
                }
            }
        });

        return relacionados;

    }

    public buscarProdutoPorCodigo(cod: string): Produto | null {
        return this.produtos.find(produto => produto.codPro == cod) ?? null;
    }

    public sendContactForm(objectContato: Contato) {
        let dados = JSON.stringify(objectContato);
        return this.http.post<Contato>(
            `https://uhy7ql21ce.execute-api.us-east-1.amazonaws.com/production`,
            dados
        )
    }

    public sendFile(formData: any, fileName: string, prefix: string) {
        return this.http.put(`https://uhy7ql21ce.execute-api.us-east-1.amazonaws.com/production/upload-file/${prefix}-${fileName}`, formData)
    }

    public getLancamentos(): Observable<Produto[]> {
        const itens = this.http.get<Produto[]>(`${this.censi_url}/lancamentos.json`);
        return itens;
    }

    public getVideos(): Observable<any[]> {
        const itens = this.http.get<any[]>(`${this.censi_url}/videos.json`);
        return itens;
    }
    public getVideosInHome(): Observable<{items:Video[]}> {
        const itens = this.http.get<{items:Video[]}>(`${this.censi_url}/home-videos.json`);
        return itens;
    }


    public getLogos(): Observable<any[]> {
        const itens = this.http.get<any[]>(`${this.censi_url}/identidade-visual.json`);
        return itens;
    }

    public getFotos(): Observable<Foto[]> {
        const itens = this.http.get<any[]>(`${this.censi_url}/fotos.json`);
        return itens;

    }
    public getFotosSobre(): Observable<any[]> {
        const itens = this.http.get<any[]>(`${this.censi_url}/fotos-sobre.json`);
        return itens;
    }
    public getDownloads(): Observable<Download[]> {
        const itens = this.http.get<Download[]>(`${this.censi_url}/downloads.json`);
        return itens;
    }

    public async getCapas() {
        if(this.capas && this.capas.length > 0){
            return this.capas
        }
        return await this.http.get<any[]>(`${this.censi_url}/capassecoes.json`).toPromise().then(res =>{
            //console.log(res)
            if(res)
              this.capas = res
            return res;
          });
    }
    public getGibis(): Observable<any[]> {
        const itens = this.http.get<any[]>(`${this.censi_url}/gibis.json`);
        return itens;
    }
    public getFileContent(url: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(
            `${url}`,
            { responseType: 'blob' }
        );

    }
    public getCatalogos(): Observable<any[]> {
        const itens = this.http.get<any[]>(`${this.censi_url}/catalogos.json`);
        return itens;
    }
    public getPapeisdeParede(): Observable<any[]> {
        const itens = this.http.get<any[]>(`${this.censi_url}/papeis-de-parede.json`);
        return itens;
    }
    public getDepoimentosDoCliente(): Observable<any[]> {
        const itens = this.http.get<any[]>(`${this.censi_url}/depoimentos.json`);
        return itens;
    }

    public getCategorias(): Observable<Menu[]> {
        return this.http.get<Menu[]>(`${this.censi_url}/categorias.json`);
    }

    public getDesenhoTecnico(): Observable<DesenhoTecnico[]> {
        return this.http.get<DesenhoTecnico[]>(`${this.censi_url}/desenho-tecnico.json`);
    }

    public getRelacionados(): Observable<Relacionado[]> {
        return this.http.get<Relacionado[]>(`${this.censi_url}/relacionados.json`);
    }
    public getEstadosCidades(): Observable<Estado[]> {
        return this.http.get<Estado[]>(`${this.censi_url}/estados-cidades.json`);
    }
    public getCover(post: Post){

        if(post){
          if( post.midias.length > 0){
            const url = post.midias[0].media_details.sizes.full.source_url;
            if(url != '' && url != undefined){
                return url
            }
          } 
        }
        return 'assets/images/blog_default_cover.png'
      }

       // Função para remover acentos e caracteres especiais
    public normalizeString(str: string): string {
        if(!str) return str;
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    verifyMatch(keywords: string[], productProperty: string) {
        let match = true;
        keywords.forEach(keyword => {
            let productPropertyNormalized = this.normalizeString(productProperty.toLowerCase());
            if(!productPropertyNormalized.includes(keyword)){
                match = false;
            }
        });
        
        return match;

    }

    public filterProductsSearch (produtos: Array<Produto>, searchString: string): Array<Produto> {
  
        let startsCodProProducts: Produto[] = [];
        let includesCodProProducts: Produto[] = [];
        let moreProducts: Produto[] = [];
        let startsNomeResults: Produto[] = [];
        let includesNomeResults: Produto[] = [];
        let finalResults: Produto[] = [];
        let keywordNormalized = this.normalizeString(searchString?.toLowerCase())
        var keywords = keywordNormalized.split(" ");
        
        if(keywords.length == 1){

            produtos.forEach(produto => {
                let keyTemp = keywords[0];
                if(produto.codPro.startsWith(keyTemp)){
                    startsCodProProducts.push(produto)
                }
            });
        
            produtos.forEach(produto => {
                let keyTemp = keywords[0];
                if(produto.codPro.includes(keyTemp)){
                    includesCodProProducts.push(produto)
                }
            });
            const combinedSet = new Set([...startsCodProProducts, ...includesCodProProducts]);
            
            finalResults =  Array.from(combinedSet);
        
        }

        if((keywords.length == 1 && finalResults.length == 0) || keywords.length > 1){
            produtos.forEach(produto => {
                let nomeNormalized = this.normalizeString(produto.nome.toLocaleLowerCase());
                let nomeCurtoNormalized = this.normalizeString(produto.nome.toLocaleLowerCase());
                let desProNormalized = this.normalizeString(produto.nome.toLocaleLowerCase());

                if(nomeNormalized.startsWith(keywordNormalized) || nomeCurtoNormalized.startsWith(keywordNormalized) || desProNormalized.startsWith(keywordNormalized) ){
                    startsNomeResults.push(produto)
                }
            });
        
            produtos.forEach(produto => {
                let nomeNormalized = this.normalizeString(produto.nome.toLocaleLowerCase());
                let nomeCurtoNormalized = this.normalizeString(produto.nome.toLocaleLowerCase());
                let desProNormalized = this.normalizeString(produto.nome.toLocaleLowerCase());

                if(nomeNormalized.includes(keywordNormalized) || nomeCurtoNormalized.includes(keywordNormalized) || desProNormalized.includes(keywordNormalized) ){
                    includesNomeResults.push(produto)
                }
            });

            const combinedSet = new Set([...startsNomeResults, ...includesNomeResults]);
            finalResults =  Array.from(combinedSet);
        }
        
        if(finalResults.length == 0){
            moreProducts = produtos.filter(produto => {
                let matchName = this.verifyMatch(keywords, produto.nome);
                let matchNameShort = this.verifyMatch(keywords, produto.nomeCurto);
                let matchDesPro = this.verifyMatch(keywords, produto.desPro);
                
                return matchName || matchNameShort || matchDesPro;
            });
            
            finalResults = moreProducts;
        }
        

        return finalResults
    }

    public filterProducts(produtos: Array<Produto>, searchString: string): Array<Produto> {

        var keyword = searchString?.toLowerCase();
        var codProResults = produtos.filter(product => (
            (product.codPro + "").indexOf(keyword) > -1
        ));

        var nomeResults = produtos.filter(product => (
            product.nome.toLowerCase().indexOf(keyword) > -1
            || product.nomeCurto.toLowerCase().indexOf(keyword) > -1
        ));

        var descricaoResults = produtos.filter(product => (
            product.desPro.toLowerCase().indexOf(keyword) > -1
            || product.descricao.toLowerCase().indexOf(keyword) > -1
            || (product.descricaoTecnica + "").toLowerCase().indexOf(keyword) > -1
            || product.descricaoTecnicaAlternativa.toLowerCase().indexOf(keyword) > -1
        ));

        var finalResults = [];


        for (let i = 0; i < codProResults.length; i++) {
            if (finalResults.indexOf(codProResults[i]) == -1)
                finalResults.push(codProResults[i])
        }
        for (let i = 0; i < nomeResults.length; i++) {
            if (finalResults.indexOf(nomeResults[i]) == -1)
                finalResults.push(nomeResults[i])
        }
        for (let i = 0; i < descricaoResults.length; i++) {
            if (finalResults.indexOf(descricaoResults[i]) == -1)
                finalResults.push(descricaoResults[i])
        }

        finalResults.sort((a, b) => {
            return Number(a.nome) - Number(b.nome);
        });

        return finalResults.slice(0, 100);

    }

    // <-------------- Start Blog -------------->

    loadPosts() {
        this.http.get<Post[]>(`${this.censi_url}/blog_posts.json`)
            .subscribe(itens => {
                this.posts = itens;
                this.postsEventEmitter.emit(this.posts);
            }
            );
    }
    loadPost(id: number) {
        this.http.get<Post[]>(`${this.censi_url}/blog_posts.json`)
            .subscribe(itens => {
                itens.forEach(element => {
                    if (element.id == id) {
                        this.postEventEmitter.emit(element);
                    }
                });

            }
            );
    }

    paginate(array: any[], page_size: number, page_number: number) {

        return array.slice(page_number * page_size, (page_number + 1) * page_size);
    }

    public getPostsWithHeaders(perPage: number, pageIndex: number, tagId: string, categoriaId: string): Post[] {
        this.filteredPosts = this.posts;
        if (tagId != '') {
            this.filteredPosts = []
            this.posts.forEach(post => {
                post.tags.forEach(tag => {
                    if (tag == tagId) {
                        this.filteredPosts.push(post)
                    }
                });
            });
        }
        if (categoriaId != '') {
            this.filteredPosts = []
            this.posts.forEach(post => {
                post.categories.forEach(categoria => {
                    if (categoria == categoriaId) {
                        this.filteredPosts.push(post)
                    }
                });
            });
        }

        return this.paginate(this.filteredPosts, perPage, pageIndex)

    }
    public getTags(): Observable<any[]> {
        const itens = this.http.get<any[]>(`${this.censi_url}/blog_tags.json`);
        return itens;

    }
    public getPostsCategories(): Observable<any[]> {
        const categorias = this.http.get<any[]>(`${this.censi_url}/blog_categories.json`);

        return categorias;
    }
    public getPostById(id: number): any[] {
        const posts = this.http.get<any[]>(`${this.censi_url}/blog_posts.json`)

        posts.forEach(post => {
                  });
        return [];
    }

    getLocationClientes(estado: string, cidade: string, cep?: string, dist?: string,  lat?: number, lng?: number): Observable<any> {
        let api = 'https://fn6gtdeso7ojqwzeklxnw5vily0aqbxq.lambda-url.us-east-1.on.aws';
        let params = new HttpParams();
        if (estado) params = params.set('estado', estado);
        if (cidade) params = params.set('cidade', cidade);
        if (cep) params = params.set('cep', cep);
        if (dist) params = params.set('dist', dist.toString());
        if (lat) params = params.set('lat', lat.toString());
        if (lng) params = params.set('lng', lng.toString());
    
        return this.http.get(api, { params });
    }

    public async getLabelAndColorBySection(section: string): Promise<{ label: string, color: string, subLabel: string, subColor: string  } | null> {
        try {
          const res = await this.http.get<any[]>(`${this.censi_url}/capassecoes.json`).toPromise();
          
          if (res) {
            for (const item of res) {
              if (item.secao === section) {
                return { label: item.label, color: item.color, subLabel: item.subLabel, subColor: item.subColor};
              }
            }
          }
          
          return null;
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
          return null;
        }
      }
}

