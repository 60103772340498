import { Component, OnInit } from '@angular/core';

import { Produto } from './../../app.models';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-censi-lancamentos',
  templateUrl: './censi-lancamentos.component.html',
  styleUrls: ['./censi-lancamentos.component.scss']
})
export class CensiLancamentosComponent implements OnInit {
  loaded: boolean = false;
  produtos: Produto[] = [];

  constructor(
    public apiService: ApiService
  ) {} 

  ngOnInit() {
    this.apiService.getLancamentos().subscribe(itens => {
      this.produtos = itens;
      this.loaded = true; 
    });
  }

}
