<div mat-dialog-title>
    <input (keyup)="onKeyDownEvent($event)" type="text" placeholder="Encontre os produtos Censi" />
</div>
<mat-dialog-content>
    <ul class="list-search">
       
            <li  *ngFor="let item of products; let i = index" class="item-search" mat-dialog-close routerLink="/produto/{{item.slug}}">
            
                <div class="item-search__images">
                    <img class="w-100" [src]="item.imgMiniatura" alt="">
                </div>
                <div class="item-search__labels">
                    <span>Ref.: {{ item.codPro }}</span>
                    <p class="product-name">{{ item.nome }}</p>
                </div>
                <div class="item-search__buttons">
                <button mat-dialog-close mat-button>VER PRODUTO</button>              
                </div>
        </li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close class="colors-white-blue">FECHAR</button>
    <button mat-raised-button mat-dialog-close class="colors-blue-white" (click)="verMais()">
        {{ searchString.length === 0 ? 'MOSTRAR TODOS' : 'VER MAIS' }}
      </button>
      
</mat-dialog-actions>
