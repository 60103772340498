import { Component, OnInit, Input  } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { TimeInterval } from 'rxjs/internal/operators/timeInterval';
import { ApiService } from 'src/app/api.service';
import { Produto } from 'src/app/app.models';

@Component({
  selector: 'app-censi-modal-search',
  templateUrl: './censi-modal-search.component.html',
  styleUrls: ['./censi-modal-search.component.scss']
})
export class CensiModalSearchComponent implements OnInit {
  products: Produto[] = [];
  timer:any;
  searchString: string = '';
  // let timer,
  // timeoutVal = 500;

 

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {

   }

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts() {
    this.apiService.getAllProducts().subscribe(
      res => {
        this.products = res.slice(0, 15);
      }
    );
  }

  searchProducts() {

    if(this.searchString.length <= 1){
      this.apiService.getAllProducts().subscribe(
        res => {
          this.products = res.slice(0, 15);
        }
      );
      return
    }

    this.apiService.getAllProducts().subscribe(
      lista => {
       this.products = this.apiService.filterProductsSearch(lista, this.searchString).slice(0 , 15);
      }
    );
  }

  verMais(){
    if(this.searchString.length == 0){
      this.router.navigate(['/produtos', " "])
    }else{
    this.router.navigate(['/produtos', this.searchString])
    }
  }

  onKeyDownEvent(event: any){
    
    clearTimeout(this.timer);


    this.timer = setTimeout(() => {
      this.searchString = event.target.value;
      this.searchProducts(); 
  }, 500);
  

    // this.searchProducts(event.target.value); 
  }

}
function input() {
  throw new Error('Function not implemented.');
}

