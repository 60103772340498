import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});


// document.body.setScaledFont = function (f) {
//   var s = this.offsetWidth,
//       fs = s * f;
//   this.style.fontSize = fs + '%';
//   return this
// };

// document.body.setScaledFont(0.35);
// window.onresize = function () {
//   document.body.setScaledFont(0.35);
// }