<div class="section-area-total">
    <div class="section">
        <div class="linhas-header">
            <span class="title-default title-default__blue">CONHEÇA NOSSA LINHA DE PRODUTOS</span>
            <hr class="blue-censi-underlined">
            <span class="subtitle-default"> Quem quer o melhor, escolhe CENSI</span>
        </div>

    </div>
    <div class="section section-2">
        <div class="justify-itens size-area-card">
            <app-censi-categorias></app-censi-categorias>
        </div>
    </div>

    <div class="section">
        <div class="linhas-header">
            <span class="title-2">NÃO ENCONTROU O QUE PROCURA?<br />
                ENTÃO FAÇA UMA BUSCA EM NOSSO CATÁLOGO</span>
        </div>
        <div class="section-area-busca">
            <input class="section-area-buscar" type="text" (click)="openModalSearch()"
                placeholder="Digite o que você está buscando" outline:none />
            <div class="area-busca-icon">
                <a href=""><img src="/assets/bx-search.svg" alt=""></a>
            </div>
        </div>

    </div>

</div>