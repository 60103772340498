import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Menu } from 'src/app/theme/components/menu/menu.model';


@Component({
  selector: 'app-censi-categorias',
  templateUrl: './censi-categorias.component.html',
  styleUrls: ['./censi-categorias.component.scss']
})
export class CensiCategoriasComponent implements OnInit {
  allCategorias: Menu[] = [];
  categorias: Menu[] = [];
  subCategorias: Menu[] = [];
  hideMenu = false;
 
  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.loadCategorias(); 
  }

  private loadCategorias() {
    this.apiService.getCategorias().subscribe(
      result => {
        this.allCategorias = result;
        this.categorias = this.allCategorias
          .filter(c => c.parentId === 'PRODUTOS');
        this.categorias.map(c => c.sort = Number(c.sort));
      }
    );
  }


}
