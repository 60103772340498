<div class="container-lancamentos">
    <div class="w-100">
        <div class="lancamentos-header">
        <span class="title-default title-default__gray">LANÇAMENTOS</span>
            <hr class="blue-censi-underlined">
            <span class="subtitle-default subtitle-default__white">
                As maiores novidades do mercado você encontra aqui
            </span>
        </div>
    </div>
    <app-censi-slide-card-produto 
        class="w-100"
        [produtos]="produtos"
        *ngIf="loaded" 
        [showBadges]="false">
    </app-censi-slide-card-produto>
</div>
