import { ApiService } from 'src/app/api.service';
import { Component, OnInit } from '@angular/core';

import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-censi-banners',
  templateUrl: './censi-banners.component.html',
  styleUrls: ['./censi-banners.component.scss']
})

export class CensiBannersComponent implements OnInit { 
  public banners:any;

  constructor(public appService:AppService, public apiService:ApiService) { }
  paused = false;
  ngOnInit() {
    this.apiService.getAllBanners().subscribe(result => {
      this.banners = result;
      this.banners.sort(this.compareByPosistion);
    });
  }
  pauseOrPlay(vid: any){
    if(!vid.paused){
      vid.pause();
    } else{
      vid.play();
    }
  }

  navigateUrl(banner:any){
    if(banner && banner.url){
      window.open(banner.url);
    }
  }

   compareByPosistion(a:any, b:any) {
    return a.position - b.position;
  }
  
  ngAfterViewInit(){
    // this.config = {
    //   observer: true,
    //   slidesPerView: 1,
    //   spaceBetween: 0,       
    //   keyboard: true,
    //   navigation: true,
    //   pagination: this.pagination,
    //   grabCursor: true,        
    //   loop: false,
    //   preloadImages: false,
    //   lazy: true 
    // }
  }

}
