export class Restaurant {
    constructor(public id: number,
                public name: string,
                public address: string,
                public phone: string){}
}

export class Employee {
    constructor(public id: number,
                public image: string,
                public firstName: string,
                public lastName: string,
                public middleName: string,
                public email: string,
                public phone: string,
                public addresses: Address[], 
                public position: Position){}
}

//['General Manager','Assistant Manager'] ... https://aptito.com/blog/restaurant-positions-and-descriptions
export class Position {
    constructor(public id: number,
                public name: string){}
}

export class Address {
    constructor(public id: number,
                public country: Country, 
                public city: string,
                public place: string,
                public postalCode: string,
                public addressLine: string){}
}

export class Country {
    constructor(public name: string,
                public code: string){}
}

export class Customer {
    constructor(public id: number,
                public fullName: string, 
                public email: string,
                public phoneNumber: string,
                public address: string){}
}

export class Reservation {
    constructor(public id: number,
                public date: string,
                public time: string,
                public customer: Customer,
                public guests: number,
                public tableNumber: number,
                public status: ReservationStatus){}
} 

// Approved, Cancelled, Pending
export class ReservationStatus {
    constructor(public id: number,
                public name: string){}
}
 

export class Order {
    constructor(public id: number,
                public date: string,
                public items: MenuItem[],
                public quantity: number,
                public amount: number,
                public status: OrderStatus){}
}  

//Completed, Processing, On Hold, Refunded, Pending
export class OrderStatus {
    constructor(public id: number,
                public name: string){}
}

export class Video {
    constructor(public showInHome: boolean,
                ){}
}

export class MenuItem {
    constructor(public id: number,
                public name: string,
                public description: string,
                public price: number,
                public image: MenuItemImage,
                public discount: number,
                public ratingsCount: number,
                public ratingsValue: number,
                public availibilityCount: number,
                public cartCount: number,
                public weight: number,
                public isVegetarian: boolean,
                public categoryId: number){}
}

export class MenuItemImage {
    constructor(public small: string,
                public medium: string,
                public big: string){ }
}

export class Category {
    constructor(public id: number,
                public name: string,
                public description: string){ }
}   

export class Pagination {
    constructor(public page: number,
                public perPage: number,
                public prePage: number | null,
                public nextPage: number | null,
                public total: number,
                public totalPages: number){ }
}
export class Estado {
    constructor(public sigla: string,
                public nome: string,  
                public cidades: string[]           
                ){ }
}
export class Setor {
    constructor(
                public id: number,
                public value: string,         
                public viewValue: string         
                ){ }
} 
export class Contato {
    public tipo: string = 'CONTATO'; // CONTATO, VAGA
    public fullName: string = ''
    public phoneNumber: string = ''
    public estado: string = ''
    public setor: string = ''
    public email: string = ''
    public cnpj: string = ''
    public cidade: string = ''
    public contactSubject: string = ''
    public message: string = ''
    public recaptcha: string = ''
    public curriculoFileUrl: string = ''
    
    constructor(
        

                ){ }
}

export class Produto {
    constructor(public id: string,
                public categoria_id: string,
                public categorias: string,
                public codPro: string,
                public desPro: string,
                public nome: string,
                public nomeCurto: string,
                public slug: string,
                public descricao: string,
                public descricaoTecnica: string,
                public descricaoTecnicaAlternativa: string,
                public tagDestaque: string,
                public tagLancamento: string,
                public tagEconomico: string,
                public tagExclusivo: string,
                public tagPromocao: string,
                public fichaTecnica: string,
                public acabamento: string,
                public video1: string,
                public video2: string,
                public imgPrincipal: string,
                public imgOriginal: string,
                public imgMiniatura: string,
                public imgBanner: string,
                public imgThumb: string,
                public ordem: string|number,
                public codFam: string,
                public createdAt: string,
                public manualTecnico: string,
                public desenhoTecnico: string              
                ){ }
}
export class Download {
    constructor(public id: string,
                public nome: string,
                public descricao: string,
                public grupo: string,
                public tipo: string,
                public urlFile: string,
                public urlIcon: string,
                public sort: string
    ){}
}
export class Logo {
    constructor(public id: number,
                public arquivo: string,  
                public title: string,
                public subtitle: string,
                public img: string            
                ){ }
} 
export class Gibi {
    constructor(public id: number,
                public arquivo: string,  
                public title: string,
                public subtitle: string,
                public edicao: string,
                public img: string   
                ){ }
}
export class Catalogo {
    constructor(public id: number,
                public arquivo: string,  
                public title: string,
                public subtitle: string,
                public edicao: string,
                public img: string   
                ){ }
}     
export class PapeldeParede {
    constructor(public id: number,
                public arquivo: string,  
                public title: string,
                public subtitle: string,
                public edicao: string,
                public img: string   
                ){ }
}
export class Depoimento {
    constructor(public id: number,
                public description: string,  
                public name: string,
                public profession: string,
                public city: string,
                public uf: string               
                ){ }  
} 
export class DesenhoTecnico {
    constructor(public codPro: string,
                public urlDoc: string,
                public size: number 
                ){ }
}   
export class Relacionado {
    constructor(
        public id: number,
        public produto: string,
        public codPro: string
    ) {}

}   

export class Foto {
    constructor(
        public id: number,
        public produto: string,
        public nome: string,
        public destaque: number,
        public ordem: number,
        public urlImgOriginal: string,
        public urlImg: string,
        public urlImgMiniatura: string,
        public urlImgThumb: string
       
    ) {}
    
}   
export class FotoSobre {
    constructor(
        public id: number,
        public nome: string,
        public destaque: number,
        public ordem: number,
        public urlImgOriginal: string,
        public urlImg: string,
        public urlImgMiniatura: string,
        public urlImgThumb: string
       
    ) {}
    
}  


export class Post {
    
    public date: Date = new Date();
    public sort: number =0;
    public slug: string ='';
    public link: string ='';
    public title: any = '';
    public rendered: any = '';
    public midias: any[] = [];
    public content: any = '';
    public excerpt: any = '';
    public partial: any = '';
    public coverUrl: string ='';
    public tags: string[]= []
    public categories: string[] = []
    public categorias: Categorias[] = []
    public _embedded: any = '';
    constructor(
        public id: number   
    ) {}
} 
export class Categorias{
    public name = '';
}
export class CategoryPost{
    public id: string = '';
    public categories: string = '';
    public categorias: string = '';

    
  }

