import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';   

import { Menu } from './menu.model';
import { verticalMenuItems, horizontalMenuItems } from './menu';


  @Injectable()
export class MenuService {

  public itens:Menu[] = []

  public censi_url = environment.url + '/assets/data/censi/'; 
  
  constructor(public http:HttpClient, 
              private location:Location,
              private router:Router){ 



              } 
    
  public getVerticalMenuItems():Array<Menu> {
    return verticalMenuItems;
  }

  public getHorizontalMenuItems():Array<Menu> {
    return horizontalMenuItems;
  }

  public expandActiveSubMenu(menu:Array<Menu>){
    let url = this.location.path();
    let routerLink = decodeURIComponent(url);
    let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
    if(activeMenuItem[0]){
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != ""){  
        let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public isOnRoute(itemRouterLink:string){
    let url = this.location.path();
    let routerLink = decodeURIComponent(url);
    if( routerLink.indexOf(itemRouterLink) >= 0){
     return true;
    }return false;
  }

  public toggleMenuItem(menuId:string){
    let menuItem = document.getElementById('menu-item-'+menuId);
    let subMenu = document.getElementById('sub-menu-'+menuId);  
    if(subMenu){
      if(subMenu.classList.contains('show')){
        subMenu.classList.remove('show');
        
        menuItem?.classList.remove('expanded');
      }
      else{
        subMenu.classList.add('show');
        menuItem?.classList.add('expanded');
      }      
    }
  }

  public closeOtherSubMenus(menu:Array<Menu>, menuId:string){
    
    let currentMenuItem = menu.filter(item => item.id == menuId)[0];
    console.log(currentMenuItem);
    menu.forEach(item => {
      if((item.id != menuId && item.parentId == currentMenuItem.parentId) || (currentMenuItem.parentId == "" && item.id != menuId) ){
        let subMenu = document.getElementById('sub-menu-'+item.id);
        let menuItem = document.getElementById('menu-item-'+item.id);
        if(subMenu){
          if(subMenu.classList.contains('show')){
            subMenu.classList.remove('show');
          
            menuItem?.classList.remove('expanded');
          }              
        } 
      }
    });
  } 
 
  public closeAllSubMenus(){        
    verticalMenuItems.forEach(item => {
      let subMenu = document.getElementById('sub-menu-'+item.id);
      let menuItem = document.getElementById('menu-item-'+item.id);
      if(subMenu){
        if(subMenu.classList.contains('show')){
          subMenu.classList.remove('show');
          
          menuItem?.classList.remove('expanded');
        }              
      } 
    });           
  }
  
  public loadMenuMobileData():  Observable<Menu[]>{

         return this.http.get<Menu[]>(this.censi_url + 'menu.json');
    
    } 

  public loadMenuPrincipalData():  Observable<Menu[]>{

    return this.http.get<Menu[]>(this.censi_url + 'menu.json');
    
    } 

    public loadMenuCategoriasData():  Observable<Menu[]>{
      return this.http.get<Menu[]>(this.censi_url + 'categorias.json');
      } 

    
      

}