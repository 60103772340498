
<div class="form-main p-space">

<form id="form_contato" [formGroup]="contatoForm" #formDirective="ngForm" fxLayout="row wrap" class="reservation-form" autocomplete="off">
    <div fxFlex="100" class="mt-1">
        <mat-form-field appearance="outline" class="w-100"> 
            <mat-label>Nome</mat-label>
            <input matInput placeholder="Seu nome completo" formControlName="fullName" required>
            <mat-error *ngIf="contatoForm.controls.fullName.errors?.required">Nome é necessário</mat-error>
        </mat-form-field> 
    </div>
    
    <div fxFlex="100" fxFlex.gt-xs="50" class="" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Seu Email Comercial" formControlName="email" required>
            <mat-error *ngIf="contatoForm.controls.email.errors?.required">Email é necessário</mat-error>
            <mat-error *ngIf="contatoForm.controls.email.hasError('invalidEmail')">Endereço de email inválido</mat-error>
        </mat-form-field>
    </div> 
    <div fxFlex="100" fxFlex.gt-xs="50" class="" ngClass.sm="mt-1" ngClass.xs="mt-1"> 
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Telefone</mat-label>
            <input matInput placeholder="Seu telefone" mask="(00) 0 0000-0000|| (00) 0000-0000" formControlName="phoneNumber" required>
            <mat-error *ngIf="contatoForm.controls.phoneNumber.errors?.required">Telefone é necessário</mat-error>
        </mat-form-field> 
    </div>
    <div fxFlex="100" fxFlex.gt-xs="70"  ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100"> 
            <mat-label>Assunto</mat-label>
            <input matInput placeholder="Assunto" formControlName="contactSubject" required>
            <mat-error *ngIf="contatoForm.controls.contactSubject.errors?.required">Assunto é necessário</mat-error>
        </mat-form-field> 
    </div>
    <div fxFlex="100" fxFlex.gt-xs="30" class="" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Setor</mat-label>
            <mat-select formControlName="setor">
                <mat-option *ngFor="let setor of setores" [value]="setor.value">
                    {{setor.viewValue}}
                  </mat-option>  
          </mat-select>
          <mat-hint></mat-hint> 
        </mat-form-field> 
    </div> 
    <div fxFlex="100" class="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ mensagem }}</mat-label> 
            <textarea matInput placeholder="Como podemos ajudar?" formControlName="message" rows="6"></textarea> 
            <mat-error *ngIf="contatoForm.controls.message.errors?.required">Preencha o campo menssagem</mat-error>
        </mat-form-field>
    </div> 

    <div class="w-100 py-2 text-left">
        <p class="lgpd"><a class="lgpd-text" routerLink="/lgpd" target="_blank">Contrato LGPD</a> / <a class="lgpd-text" routerLink="/compliance" target="_blank">Compliance *</a></p>
    <input formControlName="aceite" type="checkbox" class="lgpd-check" id="lgpdCheck">
    <label class="lgpd-check-text-1" for="lgpdCheck">Eu concordo que este site armazene minhas informações enviadas para que elas possam responder à minha consulta.</label>
    <p class="lgpd-text-2">De acordo com a LGPD posso, a qualquer tempo, solicitar o acesso aos seus dados pessoais, bem como a sua retificação, eliminação, limitação do seu tratamento e a portabilidade de meus dados. Se houver dúvidas posso contatar a CENSI conforme nossa Política de Privacidade.</p> 
</div>  
        
<div class="w-100 py-2 text-right" >
    <button (click)="validateAndSend()"  mat-raised-button color="primary" class="uppercase">Enviar</button>
</div>
   
</form>
<!-- <div class="reservation-form-footer primary-color">
    você também pode nos ligar: (+55) (47) 3331-0500
</div>      -->
</div>