import { Component, Input, OnInit } from '@angular/core';
import { Foto } from 'src/app/app.models';

// import Swiper core and required modules
import SwiperCore, { Zoom, Navigation, Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Zoom, Navigation, Pagination]);


@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {

  

  @Input('fotos') fotos:any[] = [];
  public closed:boolean = true;
  

  constructor() { }

  
  ngOnInit(): void {
  }


  public showModal(imagem:any){
    this.closed = false;
    
  }
  public closeModal(){
    this.closed = true;
    
  }
}
