<div class="section">
    <!-- <div class="background_gray"> -->
        <svg class="background_gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.56 96.99">
            <defs>
                <style>
                    .cls-1 {
                        fill: #E3E3E2
                    }
                </style>
            </defs>
            <g id="Camada_2" data-name="Camada 2">
                <g id="Camada_1-2" data-name="Camada 1">
                    <path class="cls-1"
                        d="M0,97H335.56V26.82c-14.12-8.28-38-19.86-68.44-22C194.44-.44,159.24,53.18,95.05,49.1,72.78,47.68,39.42,38.84,0,0Z" />
                </g>
            </g>
        </svg>
    <!-- </div>  -->

    <div class="bounds">
        <div class="content" 
            fxLayout="row" 
            fxLayout.xs="column" 
            fxFlexFill
            fxHide.yba-min-height
           >

            <div class="sec1" fxFlex.gt-md="30">
                <div class="sec1__guy">
                    <img
                    class="img-guy-placa"
                    src="https://s3.sa-east-1.amazonaws.com/www.censi.com.br/assets/images/dolls/Agente-Censi-home.png"
                    alt="Boneco Super Censi"/>
                </div>      
            
            </div>
            <div fxFlex="70" class="sec2" fxFlex.gt-md="40">
                <div class="area-contato">
                    <div class="area-contato__title">
                        <span class="title-default title-default__blue">Fale com a censi</span>
                        <span><hr class="blue-censi-underlined"></span>
                    </div>
                    <div>
                        <span class="subtitle-default subtitle-default__black">Preencha o formulário ou utilize um dos contatos abaixo</span>
                    </div>
                </div>
                <app-censi-contato-form class="form-home" [mensagem]="'Mensagem'"></app-censi-contato-form>
            </div>
            <div fxFlex="30" class="sec3" fxFlexAlgin="center center" fxFlex.gt-md="30" >
                <div class="container-right">
                    <div class="area-sac-faq">
                        <div class="area-sac">
                            <div class="icon-sac">
                                <img class="sac" src="/assets/images/icons/sac.svg" alt="">
                            </div>
                            <div>
                                <p class="mini-title-blue">SAC</p>
                                <a class="subtitle-default-contato" href="mailto:sacc@censi.com.br?subject=SAC"
                                    title="Sac Censi ">sacc@censi.com.br</a><br />
                                <a class="mini-title-blue font-o-s" href="tel:047-3331-0500">47 3331-0500</a>
                            </div>
                        </div>
                        <a href="/area_do_cliente/perguntas_frequentes">
                            <div class="area-faq">
                                <div class="icon-faq">
                                    <img class="faq" src="/assets/images/icons/faq.svg" alt="icone faq">
                                </div>
                                <p class="mini-title-blue">FAQ</p>
                                <div>
                                    <p class="subtitle-default-contato">Acesse as perguntas<br />mais frequentes</p>
                                </div>
                            </div>
                        </a>

                    </div>
                    <a target="_blank" href="https://goo.gl/maps/tSNzW2Kc75zzzQhS6">
                        <div class="area-endereco-sac">
                            <div class="icon-img">
                                <img src="/assets/images/icons/localiza.svg" alt="">
                            </div>
                            <div class="subtitle-default-contato">
                                <a href="https://bit.ly/42bIiyf" target="_blank">
                                    Rua Bahia, 4385 - Salto Weissbach<br />
                                    Blumenu-SC | 89032-000
                                </a>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            
        </div>
        
    </div>
</div>