import { CensiModalSearchComponent } from './../../../shared/censi-modal-search/censi-modal-search.component';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service'; 

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html' ,
  styleUrls: ['./toolbar1.component.scss']
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>(); 

  constructor(
    private matDialog: MatDialog,
    public appService: AppService
  ) { }

  ngOnInit() { }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }

  openModalSearch() {
    this.matDialog.open(CensiModalSearchComponent, {
      panelClass: 'modal-search'
    });
  }
}