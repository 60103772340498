import { Component, HostListener, OnInit } from '@angular/core';

import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-censi-interativos',
  templateUrl: './censi-interativos.component.html',
  styleUrls: ['./censi-interativos.component.scss']
})
export class CensiInterativosComponent implements OnInit {
 selectedButton = 1;
//   public linhas:any;
  
  constructor(public appService:AppService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    const node = document.getElementById("animation")
    // console.log(node)
  }

  ngOnInit() {

    
    // this.linhas = this.appService.getLinhasHome(); 
  }

  ngAfterViewInit(){
    
  }

  clickedButton(index: number){
  // console.log(index)
    this.selectedButton = index;
  }
 
}


