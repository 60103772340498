<div class="container">
    <div class="img-header">
        <img class="button-close"  (click)="closeModal()" src="/assets/images/buttons/button_close.png" alt="">
    
    </div>
    <div class="img-content">
        
            <swiper  [navigation]="true" 
            [zoom]="true"
            [pagination]="{
          clickable: true
        }" class="mySwiper">
        
              <ng-template class=""  *ngFor="let imagem of fotos" swiperSlide>
                  <div class="img-for-zoom swiper-zoom-container">
                    <img alt="" [src]="imagem?.urlImgOriginal" />
                </div>
            </ng-template>
    
            </swiper>
          
    </div>
  
</div>


