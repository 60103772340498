import { LgpdModalComponent } from './theme/components/lgpd-modal/lgpd-modal.component';
import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
declare let fbq:Function;
import { GoogleTagManagerService } from 'angular-google-tag-manager';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
   
  public settings: Settings;
  constructor(public appSettings:AppSettings, 
              public router:Router,
              private gtmService: GoogleTagManagerService,
              @Inject(PLATFORM_ID) private platformId: Object,
              public translate: TranslateService
              ){
    this.settings = this.appSettings.settings;
    translate.addLangs(['pt','en','de','fr','ru','tr']);
    translate.setDefaultLang('pt'); 
    translate.use('pt');

    router.events.subscribe((y: any) => {
      if(y instanceof NavigationEnd){
        fbq('track', 'PageView');
      }
    })
  }
  ngOnInit(){
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }
  ngAfterViewInit(){ 
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0,0);
          }
        }); 
      }            
    });    
  }
  customEvent() {

    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'button-click',
      data: 'my-custom-event',
    };
    this.gtmService.pushTag(gtmTag);

    alert('this is a custom event');
  }
}
