
import { AppService } from 'src/app/app.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/theme/utils/app-validators';
import { Contato, Setor } from 'src/app/app.models';
import { ApiService } from 'src/app/api.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { SEOService } from 'src/app/seo.service';
@Component({
  selector: 'app-censi-contato-form',
  templateUrl: './censi-contato-form.component.html',
  styleUrls: ['./censi-contato-form.component.scss']
})
export class CensiContatoFormComponent implements OnInit {

  public myurl: string = '';
  public recaptchaKey: string = '';

  public setores: Setor[] = [
    { id: 1, value: 'GERAL', viewValue: 'GERAL' },
    { id: 2, value: 'SAC', viewValue: 'SAC' },
    { id: 3, value: 'COMERCIAL', viewValue: 'COMERCIAL' },
    { id: 4, value: 'FINANCEIRO', viewValue: 'FINANCEIRO' },
  ];

  public contatoForm!: FormGroup;
  public today = new Date();
  @Input('mensagem') mensagem: any;
  @ViewChild(FormGroupDirective) formDirective!: FormGroupDirective;
  
  public validateAndSend() {
    console.log(`Recaptcha v3 execution requested...`);
    this.recaptchaV3Service.execute('myAction').subscribe(
      token => {
        this.recaptchaKey = token;
        this.send();
        this.addTokenLog('Recaptcha v3 token', token);
      },
      error => {
        console.log(`Recaptcha v3 error: see console`);
        console.log(`Recaptcha v3 error:`, error);
      }
    );
  }

  constructor(
    public formBuilder: FormBuilder,
    public appService: AppService,
    public apiService: ApiService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute,
    private router: Router,
    private seoService: SEOService

  ) { }

  ngOnInit(): void {
    this.contatoForm = this.formBuilder.group({
      fullName: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, emailValidator])],
      contactSubject: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      setor: ['', Validators.required],
      message: [null, Validators.required],
      aceite: [false, Validators.requiredTrue]
    });
  }

  public async send() {
    if (this.contatoForm.valid) {
      const objectContato = this.contatoForm.value
      let contato = new Contato();
      contato.fullName = objectContato.fullName;
      contato.setor = objectContato.setor;
      contato.email = objectContato.email;
      contato.phoneNumber = objectContato.phoneNumber;
      contato.contactSubject = objectContato.contactSubject;
      contato.message = objectContato.message;
      contato.recaptcha = this.recaptchaKey;
      contato.tipo = objectContato.setor;

      try {
        if (contato.recaptcha != "") {
          await this.apiService.sendContactForm(contato).subscribe({
            next: data => {
              this.showSuccess();
            },
            error: error => {
              console.log(error)
            }

          })
        }
        else {
          console.log('recaptcha não preenchida')
        }
      }
      catch (error) {
        console.log(error)
      }

    } else {
      console.log(this.contatoForm.errors)
      this.showError()
    }

  }
  public showError() {
    let dialogRef = this.appService.openErrorDialog("Erro ao enviar contato, verifique seus dados e tente novamente!");
    dialogRef.afterClosed().subscribe(dialogResult => {
    
    });
  }
  public showSuccess() {
    this.seoService.registerPageTagManager('form-trabalhe-conosco');

    let dialogRef = this.appService.openAlertDialog("Solicitação enviada com sucesso!");
    dialogRef.afterClosed().subscribe(dialogResult => {
     this.formDirective.resetForm();
      this.contatoForm.reset();
    });

  }

  public async reload(url: string): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    return this.router.navigateByUrl(url);
  }

  public getToken(captchaResponse: any): void {
    // console.log(`Resolved captcha with response:' captchaResponse`);
  }

  // public onError(errorDetails: RecaptchaErrorParameters): void {
  //   console.log(`Recaptcha error encountered; details:`, errorDetails);
  // } 


  public addTokenLog(message: string, token: any | null) {
    // console.log("token:" + token)

  }

}
