import { Component, OnInit } from '@angular/core';

import { AppService } from 'src/app/app.service';
import Swiper from 'swiper';

@Component({
  selector: 'app-our-pictures-factory',
  templateUrl: './our-pictures-factory.component.html',
  styleUrls: ['./our-pictures-factory.component.scss']
})
export class OurPicturesFactoryComponent implements OnInit {
  public picturesFactory:any[] = [];

  constructor(public appService:AppService) { }

  ngOnInit() {
    this.picturesFactory = this.appService.picturesFactory();
  }

  ngAfterViewInit() {
    new Swiper('.mySwiper', {
      observer: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: false,
      pagination: false,
      grabCursor: true,
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true
      },
      speed: 500,
      effect: "slide",
      breakpoints: {
        280: {
          slidesPerView: 1
        },
        480: {
          slidesPerView: 2
        },
        600: {
          slidesPerView: 3
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 3
        },
        1500: {
          slidesPerView: 4
        },
        2500: {
          slidesPerView: 5
        }
      }
    });
  }

}
