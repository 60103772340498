import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Produto } from './app.models';
import { Menu } from './theme/components/menu/menu.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private titleService: Title, private meta: Meta,   private gtmService: GoogleTagManagerService,) { }

  generateMetaTags(componentType: string, data?: any) {
    switch (componentType) {
      case 'Contato':
        this.titleService.setTitle('Contato | Censi Brasil');
        this.meta.updateTag({ name: 'description', content: 'Deseja falar conosco? Envie-nos uma mensagem ou entre em contato com o nosso setor de atendimento ao consumidor.' });
        this.meta.updateTag({ name: 'keywords', content: 'Contato, Censi, Sugestão, Atendimento, Atendimento Censi' });
        break;
      case 'TrabalheConosco':
        this.titleService.setTitle('Trabalhe Conosco | Censi Brasil');
        this.meta.updateTag({ name: 'description', content: 'Trabalhe conosco. Cadastre seu currículo em nosso site.' });
        this.meta.updateTag({ name: 'keywords', content: 'Oportunidades de emprego, Vagas disponíveis, Candidatura a emprego, Carreira na Censi, Envio de currículo, Trabalhar na Censi' });
        break;
      case 'TodasCategorias':
        this.titleService.setTitle('Categorias | Censi Brasil');
        this.meta.updateTag({ name: 'description', content: 'Conheça todas as categorias de produtos da Censi Brasil.' });
        this.meta.updateTag({ name: 'keywords', content: 'Caixas Acompladas, Bacias Sanitárias, Pias, Lavatórios, Tanques, Linha, Gás, Registro, Economizadores, Acessibilidade' });
        break;
      case 'Produto':
        const produto = data as Produto;
        this.titleService.setTitle(`${produto.nomeCurto} | Censi Brasil`);
        this.meta.updateTag({ name: 'description', content: produto.desPro });
        break;
      case 'Categoria':
        const category = data as Menu;
        this.titleService.setTitle(`${category.title} | Censi Brasil`);
        this.meta.updateTag({ name: 'description', content: category.description ? category.description : category.title });
        break;
      default:
        // Se nenhum tipo de componente corresponder, mantenha as meta tags padrão
        this.titleService.setTitle('Censi Brasil');
        this.meta.updateTag({ name: 'description', content: 'Soluções em reparos e mecanismos hidráulicos inovadores, concebidos através da atenta observação das tendências do mercado, com a missão de melhorar produtos e procedimentos já existentes, além da constante renovação das linhas.' });
        this.meta.updateTag({ name: 'keywords', content: 'Censi, Censi Brasil, hidráulicos, mecanismos, produtos, caixas, conexões' });
        break;
    }
  }

  registerPageTagManager(pageName: string) {
    const gtmTag = {
      event: 'page',
      pageName: pageName
    };
    
    this.gtmService.pushTag(gtmTag);
  }
}
