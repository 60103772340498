import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { ApiService } from 'src/app/api.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  providers: [ MenuService ]
})
export class VerticalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId = "";
  public produto: any = null;
  public menuItems: Array<Menu> = []; 
  public menuCategorias: Array<Menu> = []; 
  public menuSubCategorias: Array<Menu> = []; 
  public linkSubCategoria: string = ""; 
  public menuItem: any; 

  public breadCrumb: Array<string> = [];
  @ViewChildren(MatMenuTrigger) triggers!: QueryList<MatMenuTrigger>;
  

  constructor(
    public apiService:ApiService,
    public menuService:MenuService) { }

  ngOnInit() {
    this.loadMenuItens("");
  }

  loadCategorias(parentId:string){
    this.menuService.loadMenuCategoriasData().subscribe(itens=>{
      this.menuCategorias = itens; 
       var menuItems = itens.filter(item => item.parentId == parentId); 
       menuItems.map(m => m.sort = Number(m.sort));
       this.menuItems = menuItems; 
    });
  }

  onClick(menuItem:Menu){  
     
      if(menuItem.hasSubMenu){
        this.menuItem = menuItem; 
        this.loadMenuItens(menuItem.id) 
      }
      
  }

  loadMenuItens(menuId:string){

    
    if(this.menuItem?.routerLink?.includes('produtos')  ){
      this.loadCategorias(menuId);
    }
    else    
    if(this.menuItem?.routerLink?.includes('categorias') ){
     this.loadCategorias(menuId);
   }
    else{
      this.menuService.loadMenuPrincipalData().subscribe(itens=>{   
        let menuItems = itens
        let parentId = menuId;
        menuItems = itens.filter(item => item.parentId == parentId);       
        menuItems.map(m => m.sort = Number(m.sort));  
        this.menuItems = menuItems; 
      });
    }

  
  }

  onBack(){
    // tem um problema aqui ao voltar de uma subcategoria por isto foi colocado para voltar para a home
      // this.loadMenuItens(this.menuItem.parentId)
      this.menuItem = null;
      this.loadMenuItens('');
  }


  public setCategoriaSelected(id:string) {
    this.menuCategorias.forEach(item => {
      if (item.parentId == 'PRODUTOS') {
        item.selected = false;
      }
      
      if (id == item.id) {
        item.selected = true;
      }
    });
  }

  public loadProducts(parent:Menu) {
    this.produto = null;
    this.linkSubCategoria = '';
    
    this.setSubCategoriaSelected(parent.id);

    let produtos = this.apiService.getProdutosPorCategoria(parent.id);
    this.produto = produtos[0];

    this.linkSubCategoria = parent.routerLink ?? '';
  }

  public setSubCategoriaSelected(id:string) {
    this.menuSubCategorias.forEach(item => {
      if (item.parentId != 'PRODUTOS') {
        item.selected = false;
      }
      
      if (id == item.id) {
        item.selected = true;
        this.produto = "teste";
      }
    });
  }

  public showHideMenu() {


    this.menuService.loadMenuCategoriasData().subscribe(itens=>{
      var menuItems = itens.filter(item => item.parentId == 'PRODUTOS'); 
      menuItems.map(m => m.sort = Number(m.sort));
      this.menuCategorias = menuItems;
    });
  }


  public subMenuAtivo(theItem:Menu){
    return this.menuService.isOnRoute(theItem.routerLink ?? "");
  }

  menuOpened() {
    document?.querySelector("body")?.classList.add("noscroll");
  }


  menuClosed() {
    document?.querySelector("body")?.classList.remove("noscroll");
  }


}
