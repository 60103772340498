<div class="container">
    <div class="img-header">
        <div class="area-texto">
            <p>
                Nós podemos fazer uso de cookies ou informações de navegação para melhorar a experiência de uso em nosso site.
                Ao prosseguir, você concorda que está ciente e concorda com nossa política de privacidade.
            </p>
            <div class="area-btns">
                <button 
                    href="javascript:void(0)" 
                    (click)="downloadPdf('https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/uploads/politica_privacidade_censi.pdf','politica_privacidade_censi.pdf')">
                    Ler Política de Privacidade
                </button>
                <button (click)="clickAceito()">Li e Concordo</button>
            </div>
        </div>
    </div>

</div>