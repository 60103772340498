import { LgpdModalComponent } from './theme/components/lgpd-modal/lgpd-modal.component';
import localePt from '@angular/common/locales/pt';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, Component, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; 
import { DatePipe, registerLocaleData } from '@angular/common';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';


import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; 
export function HttpLoaderFactory(httpClient: HttpClient) { 
  return new TranslateHttpLoader(httpClient, environment.url +'/assets/i18n/', '.json');
}
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorI18nService } from './theme/utils/mat-paginator-i18n.service';

import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { AppInterceptor } from './theme/utils/app-interceptor';

import { AppRoutingModule } from './app-routing.module'; 
import { SharedModule } from './shared/shared.module'; 


import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component'; 
import { Toolbar1Component } from './theme/components/toolbar1/toolbar1.component';
import { ContactsComponent } from './theme/components/contacts/contacts.component'; 
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { FooterComponent } from './theme/components/footer/footer.component'; 
import { NgxMaskModule } from 'ngx-mask';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule, ReCaptchaV3Service } from 'ng-recaptcha';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';


import Swiper, { Autoplay } from 'swiper';

Swiper.use([Autoplay]);

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    ContactsComponent, 
    Toolbar1Component,
    HorizontalMenuComponent,
    VerticalMenuComponent,
    FooterComponent,
    LgpdModalComponent
    

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }), 
    BrowserAnimationsModule,
    HttpClientModule, 
    NgProgressModule,
    NgProgressHttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot({
      dropSpecialCharacters: false
    }),
    AppRoutingModule,
    SharedModule, 
    RecaptchaV3Module,
    RecaptchaFormsModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-WW6N3Q32',
      // gtm_auth: YOUR_GTM_AUTH,
      // gtm_preview: YOUR_GTM_ENV
    })   
  ],
 
  providers: [ 
    AppSettings,
    ReCaptchaV3Service,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LfrlvAeAAAAALk9l3mBzg_iMV_qjNedsig53ugx'},
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    DatePipe,
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18nService },
    { provide: LOCALE_ID, useValue: 'pt-BR'},
    { provide: 'googleTagManagerId', useValue: 'GTM-WW6N3Q32' },
  ],
  schemas: [ 
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
