<!-- <div  fxLayout="row" fxLayoutAlign="end center">
<button fxLayout="column" fxLayoutAlign="center center" mat-button (click)="onNoClick()"><mat-icon>close</mat-icon></button>
</div> -->
<div mat-dialog-conten>  
  <div class="wrapper">
    <iframe
      width="100%"
      height="100%"
      [src]="getVideoIframeUrl(data.id) | safe" 
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen>
    </iframe>
    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: -30%;">
      <div class="c-loader"></div>
    </div>
  </div>
</div>