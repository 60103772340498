import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Produto } from 'src/app/app.models';

@Component({
  selector: 'app-censi-slide-card-produto',
  templateUrl: './censi-slide-card-produto.component.html',
  styleUrls: ['./censi-slide-card-produto.component.scss']
})
export class CensiSlideCardProdutoComponent implements OnInit {
  @Input() produtos!: Produto[];
  @Input() showBadges = true;
  loaded = false;
  pagesize = 5;
  arrayPosition = 0;
  cardSize = 265;
 
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.loadPageSize();
    this.arrayPosition = 0;
  }
  
  constructor() { }

  ngOnInit(): void {
    this.produtos.map(p => p.ordem = Number(p.ordem));
    this.loadPageSize();
  }

  loadPageSize(){
    this.cardSize = 265;
    if(this.getDocumentWidth() >= 2500){
      this.cardSize = 500;
    }
    this.pagesize = this.setPageSize(this.cardSize);
  
  }
 getDocumentWidth(){
  const value = Math.floor(document.body.offsetWidth);
  return value;
 
 }

  public goPrev() {
    this.arrayPosition -= this.pagesize;
  }
  
  public goNext(): void {
    this.arrayPosition += this.pagesize;
  }

  public getListPaginated(): Produto[] {
    let listaProdutos: Produto[] = [];
    
    for (let index = 0; index < this.pagesize; index++) {
      let pos = this.mod((this.arrayPosition + index) , this.produtos.length);
      listaProdutos.push(this.produtos[pos]);
    }
    
    
    return listaProdutos;
  }

  private setPageSize(size: number) {
    const largura = document.body.offsetWidth;
    const value = Math.floor(largura * 0.9 / size);
    return value > 0 ? value : 1;
  }

  private mod(n: number, m: number): number {
    return ((n % m) + m) % m;
  }

}
