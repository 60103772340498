import { ApiService } from 'src/app/api.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Post } from 'src/app/app.models';
import { NONE_TYPE } from '@angular/compiler';
import { Router } from '@angular/router';


@Component({
  selector: 'app-censi-blog-session',
  templateUrl: './censi-blog-session.component.html',
  styleUrls: ['./censi-blog-session.component.scss']
})
export class CensiBlogSessionComponent implements OnInit {
  pagesize = 4;
  loaded = false;
  cardSize = 325;
  public arrayPosition = 0;
  posts: any[] = [];


  @HostListener('window:resize')
  public onWindowResize(): void {
    this.configPage();

  }

  constructor(
    public apiService: ApiService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.apiService.postsEventEmitter.subscribe(p => {
      this.configPage();
      this.posts = this.apiService.posts
      // this.loadPostsInHome();
      // console.log(this.posts)
    })
  }

  configPage() {
    this.arrayPosition = 0;
    this.loaded = true;
    this.loadPageSize();

  }

  ngAfterViewInit() {
    this.apiService.loadPosts();


  }

  public getCover(post: Post) {

    if (post) {
      if (post.midias.length > 0) {
        const url = post.midias[0].media_details.sizes.full.source_url;
        if (url != '' && url != undefined) {
          return url
        }
      }
    }
    return 'assets/images/blog_default_cover.png'
  }

  getDocumentWidth() {
    const value = Math.floor(document.body.offsetWidth);
    return value;

  }

  loadPageSize() {
    this.cardSize = 325;
    if (this.getDocumentWidth() >= 2500) {
      this.cardSize = 500;
    }
      this.pagesize = this.setPageSize(this.cardSize);

  }

  private setPageSize(size: number) {
    const largura = document.body.offsetWidth;

    if (this.getDocumentWidth() <= 925 ){
      const value = Math.floor(largura * 0.95 / size)
    return value > 0 ? value : 1;
    }else{
      const value = Math.floor(largura * 0.7 / size)
      return value > 0 ? value : 1;
    } 

}

mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

getPostsInHome() {
  if (!this.loaded) return [];

  let listaPosts = [];

  for (let index = 0; index < this.pagesize; index++) {
    let pos = this.mod((this.arrayPosition + index), this.posts.length);
    listaPosts.push(this.posts[pos]);
  }

  return listaPosts;
}

openPost(post: Post){
  window.open(post.link, "_blank"); // levar para rota wordPress
  // this.router.navigate([`/blog/detalhe/${post.id}`],
  //   {
  //     state: { post: post }
  //   });

}


}
