import { Component, OnInit, Input, ViewChildren, QueryList, HostListener } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { ApiService } from 'src/app/api.service';


@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  providers: [ MenuService ]
})
export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId = "";
  public produto: any = null;
  public menuItems: Array<Menu> = []; 
  public menuCategorias: Array<Menu> = []; 
  public menuSubCategorias: Array<Menu> = []; 
  public linkSubCategoria: string = ""; 
  @ViewChildren(MatMenuTrigger) triggers!: QueryList<MatMenuTrigger>;

  @HostListener('window:resize')
  public onWindowResize():void {
    this.triggers.forEach(menu => menu.closeMenu());
  }

  constructor(public menuService:MenuService, public apiService:ApiService) { }

  ngOnInit() {
    this.menuService.loadMenuPrincipalData().subscribe(itens=>{   
      this.menuService.itens = itens;
      let menuItems = itens.filter(item => item.parentId == this.menuParentId); 
      menuItems.map(m => m.sort = Number(m.sort));
      this.menuItems = menuItems; 
    });
  }

  public showHideMenu() {
    this.menuService.loadMenuCategoriasData().subscribe(itens=>{
      var menuItems = itens.filter(item => item.parentId == 'PRODUTOS'); 
      menuItems.map(m => m.sort = Number(m.sort));
      this.menuCategorias = menuItems;
    });
  }

  
  public loadSubMenuItens(parentId:string):Menu[]{
    let itens: Menu[] = [];

    this.menuItems.forEach(element => {
        if (element.parentId == parentId) {
            itens.push(element)
        }
    });
    return itens;
  }

  public loadSubMenu(parentId:string){
    this.setCategoriaSelected(parentId);
    this.produto = null;
    
    this.menuService.loadMenuCategoriasData().subscribe(itens=>{
      var menuItems = itens.filter(item => item.parentId == parentId); 
      menuItems.map(m => m.sort = Number(m.sort));
      this.menuSubCategorias = menuItems;
    });
  }

  public setCategoriaSelected(id:string) {
    this.menuCategorias.forEach(item => {
      if (item.parentId == 'PRODUTOS') {
        item.selected = false;
      }
      
      if (id == item.id) {
        item.selected = true;
      }
    });
  }

  public loadProducts(parent:Menu) {
    this.produto = null;
    this.linkSubCategoria = '';
    
    this.setSubCategoriaSelected(parent.id);
    console.log("passou");
    let produtos = this.apiService.getProdutosPorCategoria(parent.id);

    this.produto = produtos[0];

    this.linkSubCategoria = parent.routerLink ?? '';
  }

  public setSubCategoriaSelected(id:string) {
    this.menuSubCategorias.forEach(item => {
      if (item.parentId != 'PRODUTOS') {
        item.selected = false;
      }
      
      if (id == item.id) {
        item.selected = true;
        this.produto = "teste";
      }
    });
  }

  public closeOthers(trigger:MatMenuTrigger){ 
    const currentIndex: number = this.triggers.toArray().findIndex(t => t == trigger); 
    this.triggers.forEach((menu, index) => {
      if(index != currentIndex){
        menu.closeMenu();
      }
    });
    console.log("fechar");
  }

  public subMenuAtivo(theItem:Menu){
    return this.menuService.isOnRoute(theItem.routerLink ?? "");
  }

  menuOpened() {
    document?.querySelector("body")?.classList.add("noscroll");
  }


  menuClosed() {
    document?.querySelector("body")?.classList.remove("noscroll");
  }


}
