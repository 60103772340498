<div class="area-cards-products">
    <div fxLayout="row" fxLayoutAlign="space-evenly" class="text-center badges-produtos">
        <img src="../../../assets/images/badges/badge_economizador.png" 
        alt="economizador" *ngIf="produto.tagEconomico == '1' && showBadges">
        <img src="../../../assets/images/badges/badge_exclusivo.png" 
        alt="exclusivo" *ngIf="produto.tagExclusivo == '1' && showBadges">
        <img src="../../../assets/images/badges/badge_lancamento.png" 
        alt="lancamento" *ngIf="produto.tagLancamento == '1' && showBadges">
    </div>
    <mat-card [ngClass]="{'fixed-width': fixedWidth}" fxLayout="column" fxLayoutAlign="center"
    class="card-slide-produto" routerLink="/produto/{{produto.slug}}">
        <div fxLayout="column" class="text-center mx-3 mt-4">
            <p class="description-item text-center m-0" fxLayoutAlign="start center">
                Ref.: {{produto.codPro}} <br>
            </p>
        </div>
        <div fxLayoutAlign="center" class="mx-3 my-2 img-produto">
            <img [src]="produto.imgMiniatura" [alt]="produto.nome" class="w-100">
        </div>
        <div class="tooltip description-item mx-3 mb-4">
            <span class="desc-reduzida text-center">{{ produto.nome }}</span>
            <span class="tooltiptext">{{ produto.nome }}</span>
        </div>
    </mat-card>
</div>
