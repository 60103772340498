import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CensiModalSearchComponent } from './../censi-modal-search/censi-modal-search.component';

@Component({
  selector: 'app-censi-linhas',
  templateUrl: './censi-linhas.component.html',
  styleUrls: ['./censi-linhas.component.scss']
})
export class CensiLinhasComponent implements OnInit { 

  constructor(
    private matDialog: MatDialog  
  ) { }

  ngOnInit() {
  }

  openModalSearch() {
    this.matDialog.open(CensiModalSearchComponent, {
      panelClass: 'modal-search'
    });
  }

}
