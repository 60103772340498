<span fxLayoutAlign="center center" class="item">
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">call</mat-icon>
  <span class="px-1"><a href="tel:047-3331-0500">+55 (47) 3331-0500</a></span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-xs></span>
<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-xs>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">location_on</mat-icon>
  <a href="https://bit.ly/42bIiyf" target="_blank">
    <span class="px-1">Blumenau SC, 89032-000</span>
  </a>
</span>
