<mat-toolbar id="top-toolbar" class="top-toolbar colors-blue-censi-white ">

  <div class="theme-container colors-blue-censi-white" fxLayout="row" fxLayoutAlign="space-between center">

    <div fxLayout="row" fxLayoutAlign="start center"> 
      <app-contacts fxLayout="row" fxLayoutAlign="start center"></app-contacts>         
    </div>
    <app-social-icons iconClass="min-size" fxLayout="row" fxLayoutAlign="end center" fxShow="true" fxShow.gt-md></app-social-icons>
    
  </div>           
</mat-toolbar>  
<mat-toolbar id="main-toolbar" class="mat-elevation-z2 colors-blue-white"> 
  <div class="theme-container toolbar-censi">
    <span fxHide="false" fxHide.gt-sm>
      <button mat-icon-button (click)="sidenavToggle()">
        <mat-icon class="mat-icon-lg">menu</mat-icon>
      </button> 
    </span>
    <a class="logo" routerLink="/" ><app-logo [size]="2"></app-logo></a> 
    <div class="horizontal-menu">            
      <app-horizontal-menu [menuParentId]="''"></app-horizontal-menu>
    </div> 
    <div class="area-search-toolbar">
      <span class="inpt-toolbar" (click)="openModalSearch()"> Buscar Produtos</span>
      <div class="area-icon-toolbar bnt-search-toolbar">
        <a mat-icon-button matTooltip="Busca" class="mx-2"
           (click)="openModalSearch()">

          <mat-icon [matBadge]="appService.Data.favorites.length" 
                    [matBadgeHidden]="appService.Data.favorites.length == 0" 
                    matBadgeSize="small" 
                    matBadgeColor="warn">search_border</mat-icon>
        </a> 
      </div>
    </div>
    <!-- <div fxLayout="row" fxLayoutAlign="start center"> 
      <div class="searchbar"></div>        
    </div>  -->
    
  </div>  
  <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
  <defs>
      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
  </defs>
  <g class="parallax">
      <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.5)" />
      <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
      <use xlink:href="#gentle-wave" x="48" y="10" fill="#fff" />
  </g>
</svg>   
</mat-toolbar>


<!-- <mat-toolbar>
  <div id="menu-container" class="menu-bg"></div>
</mat-toolbar> -->


