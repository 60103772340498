import { Component, HostListener, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { window } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
  id: string;
}

export type Video = {
  etag: string
  id: string
  kind: string
  snippet: {
    channelId: string
    channelTitle: string
    description: string
    playlistId: string
    position: number
    publishedAt: string
    resourceId: {
      kind: string
      videoId: string
    }
    thumbnails: {
      default: {
        height: number
        url: string
        width: number
      }
      high: {
        height: number
        url: string
        width: number
      }
      maxres: {
        height: number
        url: string
        width: number
      }
      medium: {
        height: number
        url: string
        width: number
      }
      standard: {
        height: number
        url: string
        width: number
      }
    }
    title: string
    videoOwnerChannelId: string
    videoOwnerChannelTitle: string
  }
}



@Component({
  selector: 'app-censi-videos',
  templateUrl: './censi-videos.component.html',
  styleUrls: ['./censi-videos.component.scss']
})
export class CensiVideosComponent implements OnInit { 
  public videos: any[] = [];
  public arrayPosition = 0;
  public loaded = false;
  public pagesize = 4;
  public cardSize = 325;
  
  constructor(public apiService: ApiService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.apiService.getVideosInHome().subscribe(result => {
      let listaVideos:Video[] = [];
      result.items.forEach(video => {
          listaVideos.push(video);
      });
      
      this.videos = listaVideos;
      this.arrayPosition = 0;
      this.loaded = true; 
    });
    this.loadPageSize();
    
  }

  loadPageSize(){
    if(this.getDocumentWidth() >= 2500){
      this.cardSize = 500;
    }
    this.pagesize = this.setPageSize(this.cardSize);
  
  }

  private setPageSize(size: number) {
    const largura = document.body.offsetWidth;
    const value = Math.floor(largura * 0.9 / size);
    return value > 0 ? value : 1;
  } 

  mod(n: number, m: number) {
    return ((n % m) + m) % m;
  }
  

 getDocumentWidth(){
  const value = Math.floor(document.body.offsetWidth);
  return value;
 
 }

  goPrev(){
    this.arrayPosition -= this.pagesize;
  }
  
  goNext(){
    this.arrayPosition += this.pagesize;
  }

  getVideosInHome():Video[] {
    if (!this.loaded) return [];

    let listaVideos = [];
    
    for (let index = 0; index < this.pagesize; index++) {
      let pos = this.mod((this.arrayPosition + index), this.videos.length);
      listaVideos.push(this.videos[pos]);
    }
    
    return listaVideos;
  }


  openDialog(id:string): void {
    console.log(id)
    const dialogRef = this.dialog.open(VideoModalDialogComponent, {
      panelClass: 'my-full-screen-dialog',
      data: {id: id}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

@HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.loadPageSize();
    this.arrayPosition = 0;
  }

}
@Component({
  selector: 'app-video-modal-dialog',
  templateUrl: 'video-modal-dialog.component.html',
  styleUrls: ['video-modal-dialog.component.css'],
})
export class VideoModalDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<VideoModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  getVideoIframeUrl(url:string){
    return "https://www.youtube.com/embed/"+url;
  }



}