<div id="app" class="app" [dir]="(settings.rtl) ? 'rtl' : 'ltr'"
            [ngClass]="[settings.theme, 'toolbar-'+settings.toolbar]" 
            [class.main-toolbar-fixed]="settings.mainToolbarFixed"> 
    <router-outlet></router-outlet>
    <ng-progress [spinner]="false" [meteor]="false" color="#FFCC00"></ng-progress>
  
    <app-lgpd-modal
    #lgpd_modal
    [hidden]="lgpd_modal?.closed"
    ></app-lgpd-modal>

</div>