import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-censi-produto-card',
  templateUrl: './produto-card.component.html',
  styleUrls: ['./produto-card.component.scss']
})
export class ProdutoCardComponent implements OnInit { 
  
  @Input('produto') produto: any;
  @Input('linkSubCategoria') linkSubCategoria: string = '';
  
  constructor(public appService:AppService) { }

  ngOnInit() {
    // this.produto = this.appService.loadProduto(); 
  }

  ngAfterViewInit(){
    
  }

}
