import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterByIdPipe } from './filter-by-id.pipe'; 
import { ProfilePicturePipe } from './profilePicture.pipe';
import { UserSearchPipe } from './user-search.pipe';
import { SafePipe } from './safe-pipe';
import { SortByPipe } from './sort-by.pipe';


@NgModule({
    imports: [ 
        CommonModule 
    ],
    declarations: [
        FilterByIdPipe,
        ProfilePicturePipe,
        UserSearchPipe,
        SafePipe,
        SortByPipe
    ],
    exports: [
        FilterByIdPipe,
        ProfilePicturePipe,
        UserSearchPipe,
        SafePipe,
        SortByPipe
    ]
})
export class PipesModule { }
