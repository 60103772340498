<a [routerLink]="linkSubCategoria">
    <div class="produto-main">
        <div class="produto-card">
            <div class="card-row0">
                <div class="card-title">{{produto.nome}}</div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center start">
                <div class="card-row1">
                    <img class="blue-background" [src]="produto.imgMiniatura" alt="">
                    <div fxLayoutAlign="center center" >
                        <button>VER PRODUTOS</button>
                    </div>
                </div>
                <div class="card-row2" fxLayoutAlign="start start">
                    <p class="card-description">{{produto.descricao}}</p>
                </div>
            </div>
        </div>    
    </div>
</a>