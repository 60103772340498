import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

  transform(value: any[], column: string = '', order: 'desc'|'asc' = 'asc'): any[] {
    if (!value) return value;
    if (value.length <= 1) return value;
    if (!column || column === '') 
      order === 'asc' ? value.sort() : value.sort().reverse();
    return orderBy(value, [column], [order]);
  }
}