<div class="swiper-container h-100"> 
    <div class="swiper-wrapper h-100">

        <swiper class="mySwiper">
            <ng-template *ngFor="let pictureFactory of picturesFactory" class="swiper-slide-sobre">
                <div class="award-item">
                    <img [src]="pictureFactory.image" class="swiper-lazy foto" />
                </div>
            </ng-template>
        </swiper>
    </div> 
</div>
