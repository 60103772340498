<div class="section">

    <swiper [navigation]="true"  [autoplay]="{
        delay: 8000,
        disableOnInteraction: false
      }" [speed]="500" [pagination]="{
        clickable: true
        
      }" class="mySwiper">
        <ng-template swiperSlide class="w-100" *ngFor="let banner of banners">
            <div class="produto-slider w-100" (click)="navigateUrl(banner)" [ngClass]="{'cursor-pointer': banner.url}">
                <video *ngIf="banner && banner.video" id="vid" loop oncanplay="this.play()" onloadedmetadata="this.muted = true"
                     class="w-100" preload="auto" autoPlay playsInline muted >
                    <source src="https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/banners/{{banner.id}}/{{banner.video}}" type="video/mp4" type="video/mp4"/>
                </video>
                <img *ngIf="banner && banner.image" src="https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/banners/{{banner.id}}/{{banner.image}}" />
            </div>
        </ng-template>
        <!-- <ng-template swiperSlide class="w-100">
            <div class="produto-slider w-100">
                <video id="vid" loop oncanplay="this.play()" onloadedmetadata="this.muted = true"
                     class="w-100" preload="auto" autoPlay playsInline muted >
                    <source src="/assets/video/take02.mp4" type="video/mp4" type="video/mp4"/>
                </video>
            </div>
        </ng-template>
        <ng-template swiperSlide class="w-100">
            <div class="produto-slider w-100">
                <video id="vid" loop oncanplay="this.play()" onloadedmetadata="this.muted = true"
                     class="w-100" preload="auto" autoPlay playsInline muted >
                    <source src="/assets/video/take03.mp4" type="video/mp4" type="video/mp4"/>
                </video>
            </div>
        </ng-template> -->
        
        <!-- <ng-template *ngFor="let banner of banners" swiperSlide>
            <div class="produto-slider">
                <a href="{{ banner.url }}" target="{{(banner.external == '1') ? '_blank' : '_self'}}">
                    <img class="banner-background" src="https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/banners/{{banner.id}}/{{ banner.imageOverlay }}" alt="">
                    <img *ngIf="banner.image != null" class="banner-content" src="https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/banners/{{banner.id}}/{{ banner.image }}" alt="">
                </a>
            </div>
            </ng-template> -->

    </swiper>

    <!--     <div class="px-3">
        <div class="theme-container">           
            <div class="testimonials-carousel mt-5"> -->
    <!-- <div class="swiper-container h-100" [swiper]="config">
        <div class="swiper-wrapper h-100">
            <div *ngFor="let banner of banners" class="swiper-slide">
                <div class="bannermain">
                    <img class="bannerimg" [src]="banner.image" alt="">
                    <img class="bannerimg bannerimg__mobile" [src]="banner.imageMobile" alt="">
                </div>

            </div>
            
        </div>
        
        <div class="swiper-pagination"></div>
    </div> -->
    <!--Waves Container-->

    <!--Waves end-->
    <!--</div> 
        </div>
    </div>    -->
</div>