import { Component, OnInit, Input, HostListener } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser'
import { Settings, AppSettings } from '../../app.settings';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.scss']
})
export class HeaderImageComponent implements OnInit {
  @Input('bgImageAnimate') bgImageAnimate:any;
  @Input('sectionName') sectionName:any;
  @Input('contentOffsetToTop') contentOffsetToTop:boolean = false;
  @Input('contentMinHeight') contentMinHeight:any;
  @Input('title') title:any;
  @Input('title2') title2:any;
  @Input('desc') desc:any;
  @Input('isHomePage') isHomePage:boolean = false;
  @Input('fullscreen') fullscreen: boolean = false;
  @Input('color') color: string = '#f6f6f6';
  @Input('subColor') subColor: string = '#f6f6f6';
  
  public backgroundImage:any;
  public backgroundImageMobile:any;
  public isMobile: boolean = false;  
  public bgImage:any;
  public bgImageMobile: any;
  public settings: Settings;
  public baseImageUrl = 'https://censi-site-resources.s3.sa-east-1.amazonaws.com/assets/banners/';

  constructor(public appSettings:AppSettings, private sanitizer:DomSanitizer, private apiService: ApiService) {
    this.settings = this.appSettings.settings;
    setTimeout(() => {
      this.getImagesInJson(this.sectionName);
    }); 
  }

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.screenSize();
  }

  screenSize(){
    const widthWindow = window.innerWidth || document.documentElement.clientWidth ||  document.body.clientWidth;
    this.isMobile = widthWindow < 961;
    
  }

  ngOnInit() {
    this.screenSize();

    if(this.contentOffsetToTop){
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      }); 
    } 
  }

  async getImagesInJson(sectionName:string){
    let data = await this.apiService.getCapas();
    if(data){
      let capa = data.find(capa => capa.secao == sectionName);

      if(capa){
        this.backgroundImage = this.baseImageUrl + capa.id + '/' + capa.imagecapa;
        this.backgroundImageMobile = this.baseImageUrl + capa.id + '/' + capa.imagecapaMobile;
        this.settings.headerBgImage = true;
        this.settings.headerBgImageMobile = true;
        if(this.backgroundImage){
          this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url('+this.backgroundImage +')'); 
        };
        if(this.backgroundImageMobile){
          this.bgImageMobile = this.sanitizer.bypassSecurityTrustStyle('url('+this.backgroundImageMobile +')');
        }
      }
    }
    
  }

  ngOnDestroy(){    
    setTimeout(() => {
      this.settings.headerBgImage = false;
      this.settings.headerBgImageMobile = false;  
      this.settings.contentOffsetToTop = false;
    });  
  }

}
