import { Component, OnInit, Input } from '@angular/core';
import { Produto } from 'src/app/app.models';

@Component({
  selector: 'app-censi-card-produto',
  templateUrl: './censi-card-produto.component.html',
  styleUrls: ['./censi-card-produto.component.scss']
})
export class CensiCardProdutoComponent implements OnInit {
  @Input() produto!: Produto;
  @Input() showBadges = true;
  @Input() fixedWidth = false;

  constructor() { }

  ngOnInit(): void {
  }

}
