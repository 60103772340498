<ng-container  class="color-blue-white" *ngFor="let menuItem of menuItems | sortBy:'sort'">

  <a *ngIf="menuItem.type  == 'ROUTE' && !menuItem.hasSubMenu && menuItem.routerLink"
    mat-button [routerLink]="[menuItem.routerLink]" routerLinkActive="active"
  >
    <span>{{ menuItem.title | translate }}</span>
  </a>

  <a *ngIf="menuItem.type  == 'ROUTE' && !menuItem.hasSubMenu && !menuItem.routerLink"
    mat-button [attr.href]="[menuItem.href]" target="_blank"
  >
    <span>{{ menuItem.title | translate }}</span>
  </a>

  <a *ngIf="menuItem.type  == 'LINK' && !menuItem.hasSubMenu" 
    mat-button [attr.href]="menuItem.href || ''" 
    routerLinkActive="active" [attr.target]="menuItem.target || ''"
  >
    <span>{{ menuItem.title | translate }}</span>
  </a>

  <a *ngIf="menuItem.id == 'PRODUTOS'" mat-button (click)="showHideMenu()"
    routerLinkActive="active" [ngClass]="{'active': subMenuAtivo(menuItem)}"
    [matMenuTriggerFor]="menu2" (menuClosed)="menuClosed()" (menuOpened)="menuOpened()"
  >
    <span>{{ menuItem.title | translate }}</span>
    <mat-icon class="caret">arrow_drop_down</mat-icon>
  </a>


  <a *ngIf="menuItem.id != 'PRODUTOS' && menuItem.hasSubMenu && menuItem.parentId == ''"
    mat-button routerLinkActive="active" [ngClass]="{'active': subMenuAtivo(menuItem)}"
    [matMenuTriggerFor]="menu" (menuClosed)="menuClosed()" (menuOpened)="menuOpened()"
  >
    <span>{{ menuItem.title | translate }}</span>
    <mat-icon class="caret">arrow_drop_down</mat-icon>
  </a>

  <mat-menu  #menu="matMenu" class="horizontal-sub-menu">
    <app-horizontal-menu [menuParentId]="menuItem.id"></app-horizontal-menu>
  </mat-menu>

</ng-container>

<mat-menu   #menu2="matMenu" class="menu-bg" xPosition="after">
  <div class="menu-col">
    <a class="menu-item-categoria menu-item-categoria__all"
      mat-button routerLink="/categorias"
      (mouseover)="loadSubMenu('TODOS')"
    >
      VER TODOS
    </a>
    <ng-container class="color-blue-white " *ngFor="let menuItem of menuCategorias | sortBy:'sort'">
      <a [ngClass]="{'item-selected': menuItem.selected}" class="menu-item-categoria"
        *ngIf="menuItem.type  == 'ROUTE'" mat-button 
        [routerLink]="menuItem.routerLink"
        (mouseover)="loadSubMenu(menuItem.id)"
      >
        {{ menuItem.title | translate }}
      </a>
    </ng-container>
  </div>
  <div class="menu-col">
    <ng-container class="color-blue-white " *ngFor="let menuItem of menuSubCategorias | sortBy:'sort'">
      <a [ngClass]="{'item-selected': menuItem.selected}" class="menu-item-categoria"
        *ngIf="menuItem.type  == 'ROUTE'" mat-button 
        [routerLink]="menuItem.routerLink"
        (mouseover)="loadProducts(menuItem)"
      >
        {{ menuItem.title | translate }}
      </a>
    </ng-container>
  </div>
  <div class="menu-col">
    <app-censi-produto-card
      *ngIf="produto != null"
      [produto]="produto"
      [linkSubCategoria]="linkSubCategoria"
    ></app-censi-produto-card>
  </div>
</mat-menu>

