<section class="main-content" >
    <!-- <div class="group-animations" fxLayout="row"> -->
        <div class="left-content" >
            <div class="menu-interativos">
                <div class="title-interativos w-100">
                    <div class="title-interativos-mobile">
                        <h1 class="title-default title-default__blue">
                            <span>APLICAÇÕES DOS</span><br>PRODUTOS CENSI
                        </h1>
                        <hr class="blue-censi-underlined">
                        <div class="instrucao">
                            <p class="instrucao-texto">Clique nos pontos "<img src="assets/images/icons/icone-interativos.svg" >" e saiba<br> mais sobre nossos produtos.</p>
                        </div>
                    </div>
                </div>
           
            <div class="buttons-interativo">
                <ul class="col-buttons-interativo" >
                    <li >
                        <button 
                            (click)="clickedButton(1)" 
                            [ngClass]="{'button-interativo__selected': selectedButton == 1}"
                            class="button-interativo button-interativo__unselected">
                            <span>INSTALAÇÃO VÁLVULA DE DESCARGA</span>
                        </button>
                    </li>
                    <li>
                        <button 
                            (click)="clickedButton(2)"
                            [ngClass]="{'button-interativo__selected': selectedButton == 2}" 
                            class="button-interativo button-interativo__unselected">
                            <span>O QUE TEM DENTRO DA CAIXA ACOPLADA</span>
                        </button>
                    </li>
                </ul>
                <ul class="col-buttons-interativo">
                    <li>
                        <button 
                            (click)="clickedButton(3)" 
                            [ngClass]="{'button-interativo__selected': selectedButton == 3}" 
                            class="button-interativo button-interativo__unselected">
                            <span>COMO FUNCIONA UM REPARO DE REGISTRO</span>
                        </button>
                    </li>
                    <li>
                        <button 
                            (click)="clickedButton(4)" 
                            [ngClass]="{'button-interativo__selected': selectedButton == 4}" 
                            class="button-interativo button-interativo__unselected">
                            <span>COMO INSTALAR UM SIFÃO</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        </div>
        <div class="right-content"  >
            <embed id='animation' *ngIf="selectedButton == 1" class="interativo" src="assets/animations/2023/2023.html"  />
            <embed id='animation' *ngIf="selectedButton == 2" class="interativo" src="assets/animations/caixaacoplada/caixa-acoplada.html"  />
            <embed  id='animation' *ngIf="selectedButton == 3" class="interativo" src="assets/animations/30050/30050.html" />
            <embed id='animation' *ngIf="selectedButton == 4" class="interativo" src="assets/animations/7150/7150.html"  />
        </div>
    <!-- </div> -->
</section>