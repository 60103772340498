<div class="container-videos">
    <div class="section section-header-videos">
        <span class="title-default title-default__blue">VÍDEOS</span>
        <hr class="blue-censi-underlined">
        <span class="subtitle-default subtitle-default__blue">
            Conheça mais sobre nossos produtos <br>
            assistindo aos vídeos em nosso canal no Youtube
        </span>
    </div>

    <div class="section-slide h-100 w-100 mt-3" *ngIf="loaded"> 
        <div fxLayout="row" fxLayoutAlign="space-evenly" class="container-slide h-100">
            <div *ngFor="let item of getVideosInHome()" class="slide-item"> 
                <mat-card fxLayout="column" fxLayoutAlign="center" class="card-slide-videos"  (click)="openDialog(item.snippet.resourceId.videoId)">
                    <div fxLayoutAlign="center center">
                        <mat-icon class="play-circle-outline">play_circle_outline</mat-icon>
                        <img
                            [src]="item.snippet.thumbnails.medium.url" [alt]="item.snippet.title" class="w-100"
                        />
                         <!-- (click)="video_modal?.showModal(item)" -->
                    </div>
                    <mat-card-content class="text-left mt-3">
                        <p [title]="item.snippet.title">{{item.snippet.title}}</p>
                    </mat-card-content>  
                </mat-card> 
            </div>
        
        <button class="button-slide button-slide__left" (click)="goPrev()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button class="button-slide button-slide__right" (click)="goNext()">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button> 
    </div>
</div>
</div>

<!-- <app-video-modal
  #video_modal
  [videos]="getVideosInHome()"
  [hidden]="video_modal?.closed"
></app-video-modal> -->
<!-- 
     {
        "kind": "youtube#searchResult",
        "etag": "Yq2szlRExWCFfazSCL9vZMF4Oyg",
        "id": {
            "kind": "youtube#video",
            "videoId": "RI_mSUFcFv4"
        },
        "snippet": {
            "publishedAt": "2011-12-20T16:29:16Z",
            "channelId": "UCSCZyO_5CnwGSaM-dVSr5mw",
            "title": "Compact Censi",
            "description": "O mecanismo de entrada antigo não dá sossego para o morador, mas o Agente Censi tem a solução!",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/RI_mSUFcFv4/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/RI_mSUFcFv4/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/RI_mSUFcFv4/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Censi Brasil",
            "liveBroadcastContent": "none",
            "publishTime": "2011-12-20T16:29:16Z"
        }
    },
 -->